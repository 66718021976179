/**
 * Created by gantushig on 2/18/20.
 */

import React from "react";
import "./TableContainer.scss";

const TableContainer = ({titles, rows, bkgdColor}) => {

    const ts = titles.map((item, index) => {
        return (
            <th key={index}>{item}</th>
        );
    });

    const cols = (row) => row.map((col, index) => {
        return (
            <td key={index}>{col}</td>
        );
    });

    const rws = rows.map((row, index) => {
        return (
            <tr key={index}>
                {cols(row)}
            </tr>
        );
    });
    return (
        <div style={{margin:"20px 0px 20px 0px"}}>
            <table className="tb">
                <thead style={{backgroundColor:bkgdColor || '#002c6c'}}>
                <tr>
                    {ts}
                </tr>
                </thead>
                <tbody>
                {rws}
                </tbody>
            </table>
        </div>
    )
}

export default TableContainer