/**
 * Created by gantushig on 2/18/20.
 */

import React from "react";
import {Link} from "@reach/router";


const LinkContainer = ({title, link}) => {

    return (

            <Link to={decodeURIComponent(link)}>
                {title}
            </Link>

    )
}

export default LinkContainer