import React, {Component, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import FooterList from "../components/footerList";
import Navigation from "../components/navigation";
import HeaderTop from "../components/headerTop";
import {Link} from "@reach/router";
import GoogleMapReact from 'google-map-react';
import Marker from "../components/Marker"
import {useLocation} from "@reach/router"

const Location = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    const [phone, setPhone] = useState("")
    const [note, setNote] = useState("")

    const location = useLocation();

    function createMapOptions(maps) {
        return {
            disableDefaultUI: false,
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
        };
    }

    const center = {
        lat: 47.8986,
            lng: 106.8980
    };
    const zoom = 16;
    const AnyReactComponent = ({ text }) => <div>{text}</div>;

    return (
        <div className="toolkit">
            <Helmet>
                <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6247692/752844/css/fonts.css"/>
            </Helmet>

            <header>
                <HeaderTop/>
                <Navigation apageprop={location.pathname}/>
            </header>

            <div class="container site-content flush-outside">
                <div className="row" style={{marginTop:"40px"}}>
                    <div class="col-xs-12 col-md-9">
                        <div className="content-module-container">
                            <div className="content-module-wrapper top-border-peach">
                                <div className="content-module" style={{padding:"20px", marginBottom:"20px"}}>
                                    <ul>
                                        <li>
                                        <h4>"GS1 Монгол"</h4>

                                            <p>"Морьтон" цогцолбор, 9 давхар,</p>
                                            <p>19-р хороолол, 3-р хороо,  ХУД</p>
                                            <p>УБ хот, Монгол улс </p>

<br/>
                                            <p><span>Утас:</span> +976-77 000 865</p>
                                            <p><span>Email:</span> <a href="info@gs1mn.org">info@gs1mn.org</a></p>

                                    <div style={{ width: "100%",  maxHeight: "400px", height: "400px", position: "relative", overflow: "hidden" }}>
                                        <div style={{height: "100%", width: "100%", position: "absolute", top: "0px", left: "0px", backgroundColor: "rgb(229, 227, 223)"}}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: "AIzaSyB-d1Q5VMYzzLLmyBUyUvYhiT5IjJWgDl4" }}
                                            options={createMapOptions}
                                            defaultCenter={center}
                                            defaultZoom={zoom}
                                        >
                                            <Marker
                                                lat={47.8986}
                                                lng={106.8980}
                                                name="GS1 Монгол"
                                                color="#f26334"
                                            />
                                        </GoogleMapReact>
                                        </div>
                                    </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-sm-3 hidden-xs hidden-sm">
                        <div className="content-module-container">
                            <div className="content-module-wrapper top-border-orange">
                                <div className="content-module" style={{padding:"20px", marginBottom:"20px"}}>
                            <ul class="list-group">
                                <li class="list-group-item item-l1">GS1 Global</li>
                                <ul class="list-group group-l2">
                                    <li class="list-group-item item-l2"><a href="https://www.gs1.org/contact/overview">Байршлууд
                                        </a></li>
                                </ul>
                            </ul>
                                </div></div></div>
                    </div>
                </div>
            </div>

            <FooterList />
        </div>
    )
}


export default Location;