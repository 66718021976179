import React, {useEffect, useState} from "react";
import {Link, useLocation} from "@reach/router";
import axios from "axios";
import clientConfig from "../../client-config";
import Loader from "../../images/loading.gif";
import Layout from "../../components/layout";


export default ({page}) => {
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [isFirst, setIsFirst] = useState(true);
    const [isLast, setIsLast] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState("news");
    const [nextPage, setNextPage] = useState("news");

    // const posts = data.allWordpressPost.edges
    //
    // const {currentPage, numPages, cat, title} = pageContext
    // const isFirst = currentPage === 1
    // const isLast = currentPage === numPages
    // const prevPage = currentPage - 1 === 1 ? `${cat}/` : `${cat}/` + (currentPage - 1).toString()
    // const nextPage = `${cat}/` + (currentPage + 1).toString()

    const location = useLocation();
    // function decodeHtml(html) {
    //     var txt = document.createElement("textarea");
    //     txt.innerHTML = html;
    //     return txt.value;
    // }
    const title = "Мэдээ Мэдээлэл"
    let p = 1;
    if (page !== undefined) {
        p = parseInt(page);
        //setCurrentPage( page);
    }
    const createMarkup = (data) => ({
        __html: data
    });
    const wordPressSiteURL = clientConfig.siteUrl;

    const url1 = `${wordPressSiteURL}/wp-json/wp/v2/categories?slug=brochure`;


    useEffect(() => {
        setLoading(true);
        axios.get(url1)
            .then(res1 => {
                if (res1.data.length) {
                    const id = res1.data[0]["id"];
                    const url = `${wordPressSiteURL}/wp-json/wp/v2/posts?categories=${id}&page=${p}`;
                    axios.get(url)
                        .then(res => {
                            if (res.data.length) {
                                setLoading(false);
                                setPosts(res.data)
                                let tp = parseInt(res.headers["x-wp-totalpages"], 10);
                                let t = parseInt(res.headers["x-wp-total"], 10);
                                setTotalPages(tp);
                                setTotal(t);
                                setIsFirst(p === 1);
                                setIsLast(p === tp);
                                setPrevPage(p - 1 === 1 ? `/news` : `/news/` + (p - 1).toString());
                                setNextPage(`/news/` + (p + 1).toString());
                                if (page !== undefined) {
                                    setCurrentPage(parseInt(page));
                                }
                            }
                        }).catch(err => {
                        setLoading(false);
                        setError(err.message);
                    });

                } else {
                    setLoading(false);
                    setError("No category found");
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            });

    }, [p]);

    return (
        <Layout aPageProp={location.pathname}>
            {error && <div className="alert alert-danger" dangerouslySetInnerHTML={createMarkup(error)}/>}
            {posts.length ? (
                <div>
                    <div style={{marginBottom: "20px"}}>

                        {posts.map(node => {
                            const title = node.title["rendered"]; ///decodeHtml(node.title);
                            const cntent = node.content["rendered"];

                            return (
                                <div>
                                    <h4 dangerouslySetInnerHTML={{__html: title}}></h4>


                                    <div className="row" key={node.slug}>
                                        <div className="col-sm-12" style={{paddingLeft: "20px"}}>
                                            <div style={{
                                                textAlign: "justify",
                                                textJustify: "inter-word",
                                                fontSize: "smaller"
                                            }}
                                                 dangerouslySetInnerHTML={{__html: cntent}}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <ul
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            listStyle: 'none',
                            padding: 0,
                            paddingBottom: "25px"
                        }}
                    >
                        {!isFirst && (
                            <Link to={prevPage} rel="prev">
                                Өмнөх
                            </Link>
                        )}
                        {Array.from({length: totalPages}, (_, i) => (
                            <li
                                key={`pagination-number${i + 1}`}
                                style={{
                                    margin: 0,
                                }}
                            >
                                <Link
                                    to={`/service/brochure/${i === 0 ? '' : i + 1}`}
                                    style={{
                                        padding: '10px',
                                        textDecoration: 'none',
                                        color: i + 1 === currentPage ? '#ffffff' : '',
                                        background: i + 1 === currentPage ? '#007acc' : '',
                                    }}
                                >
                                    {i + 1}
                                </Link>
                            </li>
                        ))}
                        {!isLast && (
                            <Link to={nextPage} rel="next">
                                Дараах
                            </Link>
                        )}
                    </ul>
                </div>
            ) : ''}
            {loading && <img className="loader" src={Loader} alt="Loader"/>}
        </Layout>
    )

}
