import React, {useEffect, useState} from "react";
import {useLocation} from "@reach/router";
import Layout from "../components/layout";
import he from "he";
import "../styles/wordpress.scss"
import clientConfig from "../client-config";
import axios from "axios";
import Loader from "../images/loading.gif";
import _ from "lodash"
import fm from "front-matter"
import marked from "marked"
import assets from "../assets"

export default ({localPath}) => {
    const [title, setTitle] = useState("");
    const [date, setDate] = useState(new Date());
    const [post, setPost] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        const t = assets[localPath]
        fetch(t)
            .then(response => {
                return response.text()
            })
            .then(text => {
                const content = fm(text)
                setTitle(content.attributes["title"])
                setPost(marked(content.body))
                setLoading(false);
                // this.setState({
                //     markdown: marked(text)
                // })
            })

    }, [localPath]);

    //const post = data.wordpressPost

    let showDate = true;
    if (location.pathname.startsWith('/a/')) {
        showDate = false;
    }
    return (
        <Layout aPageProp={location.pathname}>
            {!_.isEmpty(post) ? (
            <div>
                <h2>{title}</h2>
                <div className="article-content" dangerouslySetInnerHTML={{__html: post}}/>
            </div>
            )  :''}
            {loading && <img className="loader" src={Loader} alt="Loader"/>}
        </Layout>
    )
}
