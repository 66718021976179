import React from "react";
import {useLocation} from "@reach/router";
import Layout from "../../components/layout.js";
import TableContainer from "../../components/TableContainer.js";


export default () => {

    const location = useLocation();
    return (
        <Layout aPageProp={location.pathname}>
            <div>


                <h3>Элсэлтийн хураамж:</h3>

                <p>Элсэлтийн хураамжийн хувь хэмжээ нь “GS1 Mongolia”-аас тухайн хэрэглэгч гишүүнд олгосон “компанийн
                    дугаар” буюу Худалдааны эд зүйлсийн глобал дугаар (ХЭЗГД) (англиар Global Trade Item Number товчоор
                    GTIN)-ын уртаас хамаарна. Компаний дугаар нь дараах утгыг илэрхийлнэ.</p>
                <ul>
                    <li><p>8 оронтой компанийн дугаар нь 1-ээс 9999 хүртлэх нэр төрлийн бараа бүтээгдэхүүн үйлдвэрлэгчдэд
                        олгогдох буюу 9999 өөр GS1-13 дугаар авах боломжийг бүрдүүлнэ.</p>
                    </li>

                    <li><p>9 оронтой компанийн дугаар нь 1-ээс 999 хүртлэх нэр төрлийн бараа бүтээгдэхүүн үйлдвэрлэгчдэд
                        олгогдох буюу 999 өөр GS1-13 дугаар авах боломжийг бүрдүүлнэ.</p>
                    </li>

                    <li><p>10 оронтой компанийн дугаар нь 1-ээс 99 хүртлэх нэр төрлийн бараа бүтээгдэхүүн үйлдвэрлэгчдэд
                        олгогдох буюу 99 өөр GS1-13 дугаар авах боломжийг бүрдүлнэ.</p>
                    </li>

                    <li><p>11 оронтой компанийн дугаар нь 1-ээс 9 хүртлэх нэр төрлийн бараа бүтээгдэхүүн үйлдвэрлэгчдэд
                        олгогдох буюу 9 өөр GS1-13 дугаар авах боломжийг бүрдүүлнэ.</p>
                    </li>
                </ul>


                <h3>Элсэлтийн хураамж:</h3>


                <TableContainer
                    titles={[
                        "Компанийн дугаарын урт",
                        "Бүтээгдэхүүний нэгжийн тоо /хамгийн их/ ",
                        "Элсэлтийн хураамж  /төгрөгөөр/"
                    ]}
                    rows={[["7 оронтой 865ММММ", "99999 хүртэл", "1 150 000"],
                        ["8 оронтой 865МММММ", "9999 хүртэл", "750 000"],
                        ["9 оронтой 865ММММММ", "999 хүртэл", "450 000"],
                        ["10 оронтой 865МММММММ", "99 хүртэл", "350 000"],
                        ["11 оронтой 865ММММММММ", "9 хүртэл", "150 000"]]}
                    bkgdColor={"#f26334"}
                />


                <p>GS1 систем, зураасан код олгох,
                    “GS1 Mongolia” нийгэмлэгийн хэрэглэгч
                    гишүүнээр бүртгэх журмын Хавсралт №3</p>

                <TableContainer
                    titles={[
                        "Компанийн дугаарын урт",
                        "Бүтээгдэхүүний нэгжийн тоо /хамгийн их/ ",
                        "Хэрэглэгч гишүүний зураасан кодны сунгалтын төлбөр /төгрөгөөр/"
                    ]}
                    rows={[["7 оронтой 865ММММ", "99999 хүртэл", "1 000 000"],
                        ["8 оронтой 865МММММ", "9999 хүртэл", "600 000"],
                        ["9 оронтой 865ММММММ", "999 хүртэл", "300 000"],
                        ["10 оронтой 865МММММММ", "99 хүртэл", "200 000"],
                        ["11 оронтой 865ММММММММ", "9 хүртэл", "100 000"]]}
                    bkgdColor={"#002c6c"}
                />


                <p>GS1 систем, зураасан код олгох,
                    “GS1 Mongolia” нийгэмлэгийн хэрэглэгч
                    гишүүнээр бүртгэх журмын Хавсралт №4</p>

                <TableContainer
                    titles={[
                        "Нэр, төрөл",
                        "Элсэлтийн хураамж /төгрөгөөр/",
                        "Гишүүний татвар /төгрөгөөр/"
                    ]}
                    rows={[["Байршлын Глобал Дугаар (БГД) буюу Global Location Number (GLN)", "250 000", "200 000"],
                        ["Тээвэрлэлтийн Серийн Дугаар (ТСД) буюу Serial Shipping Container Code (SSCC)", "250 000", "200 000"]]}
                    bkgdColor={"#f26334"}
                />


                <p>ХААН БАНК: 562 22 72 133</p>

                <p>ХҮЛЭЭН АВАГЧИЙН НЭР: GS ONE MONGOLIA</p>

            </div>
        </Layout>
    )
}
