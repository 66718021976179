import React, {Component, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import FooterList from "../../components/footerList";
import Navigation from "../../components/navigation";
import HeaderTop from "../../components/headerTop";
import "./keys.scss";
import img from "../../images/GS1-ID-keys-hevtee-1.jpg"
import {useLocation} from "@reach/router";

export default () => {

    const location = useLocation();
    return (
        <div className="toolkit">

            <header>
                <HeaderTop/>
                <Navigation apageprop={location.pathname} />
            </header>

            <div class="container site-content flush-outside">
                <div class="col-xs-12" style={{margin:"10px"}}>
                    <h2>GS1-ийн тодорхойлогч түлхүүрүүд</h2>
                    <table className="tb2">
                        <thead>
                        <tr>
                            <th>ТОДОРХОЙЛОХ ТҮЛХҮҮР</th>
                            <th>ID KEYS</th>
                            <th>ТОДОРХОЙЛОГЧ</th>
                            <th>ХЭРЭГЛЭГДЭХ ХҮРЭЭ</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><b>ХЭЗГД</b> - Худалдааны эд зүйлсийн глобал дугаар</td>
                            <td><b>GTIN</b> – Global trade item number</td>
                            <td>
                                <p><b>Бүтээгдэхүүнүүд:</b> Төрөл бүрийн түвшний савлагаатай /хүнс, ахуй, өргөн хэрэглээний
                                бараа, түүний багц доторх нэгжийн савлагаа, хайрцаг, тавиур г.м/ өргөн хэрэглээний
                                бараа, эм, эмнэлгийн тоног төхөөрөмж, түүхий эд, материал зэрэг бүтээгдэхүүнүүд багтана.
                                </p>

                                <p><b>Үйлчилгээ:</b> тоног төхөөрөмж болон машины түрээс</p>

                                <p><b> Бие даасан худалдааны эд зүйлс</b> болон хэрэглэх заавруудыг ХЭЗГД-тай хослуулах.
                                Тухайлбал: багц/лот дугаар, серийн дугаар, хүчинтэй хугацаа дуусах өдөр, цэвэр жин г.м.

                                ISO/IEC 15459-т нийцэх 4-р хэсэг: тусгай бүтээгдэхүүн, бүтээгдэхүүний савлагаа
                                </p>
                            </td>
                            <td>Жижиглэн худалдаалах цэг буюу ПОС, яаралтай тусламж үйлчилгээний цэг, бүтээгдэхүүн
                                тээвэрлэлт болон хүлээн авалт, нөөцийн менежмент, хилийн процедурын менежмент,
                                захиалга-бэлэн мөнгө, B2C тоон мэдээлэл, гаалийн үйл ажиллагаа
                            </td>
                        </tr>
                        <tr>

                        </tr>


                        <tr>
                            <td>

                                <b>БГД</b> – Байршлын глобал дугаар
                            </td>
                            <td><b>GLN</b> – Global location number</td>
                            <td><p><b>Биет байршлууд:</b> Усан онгоцоос, усан онгоц руу, уншуулах цэг гэх мэт тухайн
                                байгууллагын газарзүйн байршлын хаяг. БГД-тай хослуулах</p>

                                <p><b>Биет байршлын дотоод тал:</b> Хадгалах сав, боомтын хаалга, зураасан код уншуулах цэг</p>

                                    <p><b>Оролцогч талууд:</b> Тухайн байгууллагын эрхзүй, үйл ажиллагаа нь бизнесийн үйл
                                ажиллагаатай холбогддог.

                                ISO 6523 стандартад хүлээн зөвшөөрөгдсөн, БГД “0088”-д зориулсан олон улсын код
                                    </p>
                            </td>

                            <td>Захиалга-бэлэн мөнгө, тээврийн менежмент, тээвэрлэлт болон хүлээн авалт, нөөцийн
                                менежмен
                            </td>
                        </tr>

                        <tr>
                            <td><b>ТЧСД</b> - Тээврийн чингэлгийн серийн дугаар</td>
                            <td><b>SSCC</b> – Serial shipping container code</td>
                            <td><p><b>Логистикийн нэгж:</b> Тавиур, хайрцаг, сав баглаанд нэгж ачаа ачих. ТЧСД-аар
                                хадгалалт, тээвэрлэлтийн зориулалтаар савлагдсан худалдааны эд зүйлсийг тодорхойлох
                                боломжтой.

                                ISO/IEC 15459-т нийцүүлж тээвэрлэлтийн нэгжийг тодорхойлох (ISO тусгай зөвшөөрлийн
                                хавтан)</p>
                            </td>
                            <td>Захиалга-бэлэн мөнгө, тээвэрлэлт болон хүлээн авалт, тээврийн менежмент, нөөцийн
                                менежмент, гаалийн үйл ажиллагаа
                            </td>
                        </tr>


                        <tr>
                            <td><b>ТТГД</b> - Тээвэрлэлтийг тодорхойлох глобал дугаар</td>
                            <td><b>GSIN</b> – Global shipment identification number</td>
                            <td><p><b>Тээвэрлэлт:</b> Хамтад нь хүргэх зориулалттай нэг юмуу түүнээс дээш логистикийн нэгж.
                                Тухайн тээвэрлэлтэд хамаарагдах логистикийн нэгжийн очих сүүлийн цэг хүртэлх
                                тээвэрлэлтийн бүхий л үе шатанд ижил ТТГД байна.</p>

                                <p>Тээвэрлэлтийн нэгдсэн бүртгэлд зориулсан Дэлхийн Гаалийн Байгууллага (ДГБ)-ын
                                    шаардлагатай танилцах /ачилтын серийн дугаар/.</p>

                                <p>Тээвэрлэлтийн нэгжүүдийг бүлэглэх</p>
                            </td>
                            <td>Захиалга-бэлэн мөнгө, тээврийн менежмент, нөөцийн менежмент, гаалийн үйл ажиллагаа</td>
                        </tr>

                        <tr>
                            <td><b>АТГД</b> - Ачилтыг тодорхойлох глобал дугаар</td>
                            <td><b>GINC</b> – Global identification number for consignment</td>
                            <td><p><b>Ачилт:</b> Аяллын хэсэгт зориулж хамтад нь тээвэрлэх зориулалттай нэг буюу түүнээс
                                дээш логистикийн нэгж /өөр ачилт хийх боломжтой/ багтана.</p>

                                <p>Тээвэрлэгч юмуу ачаа илгээгчид дараа дараагийн тээвэрлэлтийн шатуудад өөр өөр АТГД-ыг
                                    логистикийн нэгжтэй холбож болдог.</p>
                            </td>
                            <td>Тээврийн менежмент</td>
                        </tr>

                        <tr>
                            <td><b>ДАХГД</b>- Дахин ашиглах үл хөдлөх хөрөнгийн глобал дугаар</td>
                            <td><b>GRAI</b> – Global returnable asset identifier</td>
                            <td><p><b>Дахин ашиглах тээврийн эд зүйл</b> буюу тавиур, чингэлэг, модон хайрцаг зэргийг
                                голчлон тодорхойлдог.</p>

                                <p>ДАХГД нь дахин ашиглах үл хөдлөх хөрөнгийн төрлийг тодорхойлохоос гадна шаардлагатай
                                тохиолдолд нэмэлт серийн дугаарын тусламжтайгаар дахин тусгай дахин ашиглах үл хөдлөх
                                    хөрөнгийг тодорхойлдог.</p>
                            </td>
                            <td>Үл хөдлөх хөрөнгийн менежмент, тээврийн менежмент, тээвэрлэлт болон хүлээн авалт
                            </td>
                        </tr>

                        <tr>
                            <td><b>ҮХХГД</b> - Үндсэн үл хөдлөх хөрөнгийн глобал дугаар</td>
                            <td><b>GIAI</b> – Global individual asset identifier</td>
                            <td><p><b>Үндсэн хөрөнгө:</b> Албан тасалгааны тоног төхөөрөмж, тээврийн тоног төхөөрөмж,
                                мэдээлэл технологийн тоног төхөөрөмж, тээврийн хэрэгсэл гэх мэт.</p>

                                <p>ҮХХГД нь үл хөдлөх хөрөнгийн төрлөөс үл хамааран тусгай үл хөдлөх хөрөнгийг
                                    тодорхойлдог.</p>
                            </td>
                            <td>Тээврийн менежмент, нөөцийн менежмент, үл хөдлөх хөрөнгийн менежмент</td>
                        </tr>

                        <tr>
                            <td><b>ҮХГД</b> -Үйлчилгээний харилцааны глобал дугаар</td>
                        <td><b>GSRN</b> – Global service relation number</td>
                            <td><p><b>Тухайн байгууллагын үйлчилгээ үзүүлэгчийн харилцаа</b> буюу үйлчилгээ үзүүлэгч
                                тухайлбал, эмнэлэгт ажилладаг эмч нар.</p>

                                <p><b>Тухайн байгууллагын өгч буй үйлчилгээ авагчийн харилцаа буюу үйлчилгээ авагч:</b>
                                 жижиглэн худалдаа эрхлэгчдийн үйлчлүүлэгчдийн үнэнч данс, эмнэлэг дээрх өвчтөнүүдийн
                                    бүртгэл, цахим эрчим хүчний компанаар үйлчлүүлэгчдийн данс г.м.</p>
                            </td>
                            <td>Түргэн тусламж үйлчилгээний цэг, хэрэглээний тайлан, нэхэмжлэх</td>
                        </tr>

                        <tr>
                            <td><b>ББГД</b> - Баримт бичгийн глобал дугаар</td>
                            <td><b>GDTI</b> – Global document type identifier</td>
                            <td><p><b>Биет бичиг баримт:</b> Гэрчилгээ, нэхэмжлэх, жолооны үнэмлэх г.м.</p>

                                <p><b>Цахим бичиг баримт:</b> Дижитал зураг, электрон өгөгдөл солилцооны мэдээнүүд. ББГД нь
                                бичиг баримтын төрлийг тодорхойлдог. Шаардлагатай тохиололд нэмэлт серийн дугаараар
                                дамжуулан тусгай бичиг баримтуудыг тодорхойлно.</p>
                            </td>
                            <td>Бичиг баримтын ул мөрийг мөшгих, архивлах, нотлох бичиг баримт</td>
                        </tr>

                        <tr>
                            <td><b>КД</b> - Купоны дугаар</td>
                            <td><b>GCN</b> – Global coupon number</td>
                            <td><p><b>Тоон купон.</b> КД нь купон санал болгогчийг тодорхойлдог.</p>

                                <p>Шаардлагатай тохиолдолд <b>нэмэлт серийн нэгдлээр</b> дамжуулан тусгай купоныг
                                тодорхойлдог.</p>
                            </td>
                            <td>Купоны менежмент /түгээлт, баталгаажуулалт, буцаалт, төлбөр тооцоо/</td>
                        </tr>

                        <tr>
                            <td><b>НХТ</b> - Нэгдэл ба хэсэгчилсэн тодорхойлогч</td>
                            <td><b>CPID</b> – Component / part identifier</td>
                            <td><p><b>Нэгдэл ба хэсэгчилсэн:</b> Угаалгын машиныг ажиллуулах хөдөлгүүр, тийрэлтэт
                                хөдөлгүүрийн сэнс, угсралт, тээврийн хэрэгслийг асаах хөдөлгүүр, өргөх хөшүүрэг г.м</p>

                                <p><b>Тусгай нэгдэл эсвэл хэсэгчилсэн:</b> НХТ-ийн серийн дугаартай хослуулах. НХТ-ийг
                                нээлттэй нийлүүлэлтийн сувагт ашигладаггүй бөгөөд үүнийг харилцан тохиролцож ашиглахыг
                                    хориглодог.</p>
                            </td>
                            <td>Нэгдэл / хэсэгчилсэн үйлдвэрлэл, нийлүүлэлт болон борлуулалтын үйл явц</td>
                        </tr>

                        </tbody>
                    </table>
                    <div className="parent">
                            <img className="child" src={img}/>
                    </div>

                </div>

            </div>

            <FooterList />
        </div>
    )
}
