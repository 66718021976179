import React from "react";
import { useLocation, Link } from "@reach/router"
import Layout from "../../components/layout";
import "../../styles/wordpress.scss"
import data from "../../data/barcodes.json"

export default () => {
    const text1 = data.text1
    const text2 = data.text2
    const image1 = data.image1
    const image2 = data.image2
    const image3 = data.image3
    const image4 = data.image4
    const link = data.link

    const location = useLocation();
    return (

        <Layout aPageProp={location.pathname}>
            <h2>2 хэмжээст цэгэн код</h2>
            <div className="article-content" style={{padding:"10px"}}>
                <table>
                    <tr>
                        <td><img style={{marginBottom:"0px"}} src={image1}/></td>
                        <td>
                            <img style={{marginBottom:"0px"}} src={image2}/>
                            <br/>
                            <img style={{marginBottom:"0px"}} src={image3}/>
                            <br/>
                        </td>
                    </tr>
                </table>

                <br/>
                <p>{text1}</p>
                <p>{text2}</p>
                <img style={{marginBottom:"20px"}} src={image4}/>
                <br/>
                <Link to={link}>Энд дарж дэлгэрэнгүй мэдээллийг үзнэ үү.</Link>
            </div>
        </Layout>
    );
}