import React, {Component, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import FooterList from "../../components/footerList";
import Navigation from "../../components/navigation";
import HeaderTop from "../../components/headerTop";
import "./keys.scss";
import img from "../../images/GS1-ID-keys-hevtee-1.jpg"
import {useLocation} from "@reach/router";
import Layout from "../../components/layout.js";
import TableContainer from "../../components/TableContainer.js";


export default () => {

    const location = useLocation();
    return (
        <Layout aPageProp={location.pathname}>
            <div>
                <h2>GS1-ын нэр томьёоны тайлбар ба тодорхойлолт</h2>

                <TableContainer
                    titles={[
                        "Товчлол",
                        "Тайлбар",
                        "Орчуулга",
                        "Товчилсон үг",
                        "Тайлбар"
                    ]}
                    rows={[
                        ["2D","2 dimensional","2 хэмжээст","2Д","Хараа зүйн талаасаа нэвтрэх мессежэд хөндлөн болон босоо аль ч байдлаар уншигдах боломжтой тэмдэгтийг 2 хэмжээст тэмдэгт гэдэг бөгөөд 2 төрөлтэй: Матрикс тэмдэгт буюу олон эгнээт тэмдэгт: 2 хэмжээст тэмдэгт нь алдааг илрүүлэх болон засах онцлог шинжийг агуулсан байдаг. (ISO/IEC 16022 тайлбарлав.)."],
                        ["","Add-On Symbol","Нэмэлт тэмдэглэгээ","","Энэ тэмдэгт нь үндсэн зураасан кодон дээр нэмэлт мэдээллийг кодлоход ашиглагдддаг бөгөөд зураасан кодны баруун талд параллель хэлбэрээр байрлана."],
                        ["AIDC","Automatic Identification Data Capture","Мэдээллийг автоматаар олж авах","","Энэ технологийг мэдээллийг автоматаар татаж авахад ашиглагдана. Мэдээллийг автоматаар татаж авах технологиудад зураасан кодны тэмдэгтүүд, ухаалаг картууд, биохэмжүүрт болон радио давтамжит долгионоор таних зэрэг орно."],
                        ["AIDC Data Validation","Automatic Identification Data Capture, data validation","Автоматаар олж авсан мэдээллийг баталгаажуулах","","Зураасан код болон РДДТ шошгоноос уншигдсан болон скандсан мэдээлэл нь өгөгдөл дэх урьдчилсан нөхцөл байдал, тодорхой хэрэглэгчидийн шаардлага болон системийн логик дэс дараалал зэрэгтэй нийцэж байгааг баталгаажуулна."],
                        ["(an)","Alpha-numeric","Үсэг-тоон","","Үсэг тоо агуулсан тэмдэгт мөн цэг таслал зэрэг тэмдэгтийг дүрслэх боломжтой."],
                        ["ANSI","American National Standards Institute","Амеркийн стандартын үндэсний институт","",""],
                        ["AI","Application Identifier","Мэдээллийг тодотгогч","МТ","Элементэн стрингийн эхэнд 2 буюу түүнээс дээш тэмдэгтээс бүрдсэн хэсэг бөгөөд формат болон утга нь дахин давтагдашгүй байдаг."],
                        ["","Asset Type","Үл хөдлөх хөрөнгийн төрөл","","Дахин ашиглагдах үл хөдлөх хөрөнгийн бүрэлдэхүүн хэсэг бөгөөд GS1 компаний префикс эзэмшигч нь дахин давтагдашгүй ДАХГД-ыг олгодог"],
                        ["","Attribute","Онцлог, шинж чанар","","Тодотгогч дугаартай холбоотой тэмдэгтийг илэрхийлсэн мэдээллийн хэсэг (Ж нь: худалдааны эд зүйлсийн глобал дугаар (GTINTM), SSCC гэх мэт )."],
                        ["","Bar Code Verification","Зураасан кодыг баталгаажуулах","","Зураасан кодны тэмдэгтийг шинжлэх ухааны үндэслэлтэйгээр баталгаажуулах нь олон улсын стандарт болон тухайн зураасан кодны Х хэмжээс дэх завсрын хэмжээ болон У хэмжээс гэрлийн эх сурвалж болох зохимжтой өнцөг зэрэг дээр үндэслэн хийдэг."],
                        ["","Batch/Lot","Багц/лотын дугаар","","Багц болон хэсгийн дугаар нь худалдааны эд зүйлсийн гарал үүсэлтэй холбоотой үйлдвэрлэгчийн мэдээлэл юм. Мэдээлэл нь худалдааны эд зүйлс болон агуулагдсан бүтээгдэхүүнүүдэд хамааралтай."],
                        ["","Brand owner","Брэнд эзэмшигч","","GS1 системийн дугаарлалт болон тухайн бүтээгдэхүүний зураасан кодны олголтыг хариуцагч тал."],
                        ["","Carrier","Дамжуулагч","","Энэ хэсэг нь ачаа тээврийг тээвэрлэх үйлчилгээгээр     хангах эсвэл бодит буюу электрон механизмаар мэдээллийг дамжуулах ."],
                        ["CD","Check Digit","Хяналтын орон","","Элемент стрингийн бусад оронгуудаас тооцоологдсон орон нь зөв эсэхийг хянахад ашиглагдана."],
                        ["","Clear Area","","","Одоогийн хамгаалалтын бүс буюу Quit zone-ийг нэрлэсэн хуучин нэр"],
                        ["","Company Number","Компаний дугаар","","GS1 компаний префиксийн нэгдлүүд. GS1-ийн гишүүн байгууллагууд нь GS1 системийн тодорхойлох дугаар болох компаний префиксийг аж ахуйн нэгжүүдэд олгодог. Эдгээр ААН-д арилжааны компаниуд, ашгийн бус байгууллагууд, засгийн газар, хороод, байгууллагууд дахь бизнесийн нэгжүүд орно."],
                        ["CC","Composite Component®/","Холимог бүтэцтэй нэгдэл","","Энэ нэр томьѐог холимог тэмдэгт буюу 1 хэмжээст болон 2 хэмжээст тэмдэгтийн нэгдлийг тайлбарлахад ашиглагдана."],
                        ["CS","Composite Symbology","Холимог тэмдэглэгээ","","GS1 системийн холимог бүтэцтэй тэмдэгтүүд нь шугаман нэгдэл (Бүтээгдэхүүнийг энгийн байдлаар кодлох) болон 2D холимог нэгдэл /нэмэлт мэдээлэл болох багцын дугаар болон дуусах хугацаа зэрэг/-ийн нэгдэл юм. Холимог нэгдэл нь бүх төрлийн мөн 2D сканерт уншигдаж болох шугаман нэгдэл болон зэрэгцээ 2D холимог нэгдлийг агуулж байдаг."],
                        ["","Concatenation","Харилцаа холбоо","","Нэг зураасан кодны тэмдэгт дэх хэд хэдэн элемент стрингийн дүрслэл"],
                        ["","Coupon","Купон","","Кассан дээр хэрэглэгддэг үнэгүй бүтээгдэхүүн юмуу бэлэн мөнгийг илэрхийлсэн ваучер."],
                        ["","Data carrier","Өгөгдлийг дамжуулагч","","Машинд уншигдаж мэдээллийг илэрхийлэх хэлбэр ба Элемент Стрингийг автоматаар уншихад ашиглагдана."],
                        ["","Data field","Мэдээлэл/өгөгдлийн талбар","","Элемент стрингийн мэдээллийн хэсгийн ялгагдах хамгийн жижиг хэсэг"],
                        ["","Data character","Мэдээллийн таних тэмдэг","","Элемент стрингийн мэдээллийн хэсэгт илэрхийлэгдсэн үсэг, тоон тэмдэглэгээ"],
                        ["","Data titles","Өгөгдлийн нэр","","Мэдээллийн хэсэг дэх стандарт товчлолын хэсэг ба кодлогдсон мэдээллийг хүний нүдээр уншигдах хэсгийг үзүүлэхэд ашиглагдана."],
                        ["","Data Matrix","Датаматрикс","","Бие даасан хоѐр хэмжээст матрицан тэмдэглэгээ бөгөөд дөрвөлжин хэлбэрийн периметр загвараар хүрээлэгдсэн хэлбэртэй."],
                        ["DPM","Direct Part Marking","Шууд тэмдэглэх хэсэг","","Шууд тэмдэглэх хэсэг гэдэг нь бүтээгдэхүүн дээр кодыг шууд болон шууд бус аргаар тэмдэглэх үйл явцыг хамарна."],
                        ["","Direct print","Шууд хэвлэх","","Хэвлэлийн тоног төхөөрөмжүүд нь тэмдгийг бодитоор буулган хэвлэх үйл явц юм. (Жнь: флексограф, бэхэн хэвлэгч, цэгэн тэмдэглэгээ гэх мэтээр)"],
                        ["","Document Type","Баримтын төрөл","","Тухайн GS1 компаний префикс эзэмшигчээс олгосон баримт бичгийг тодорхойлох ББГД-ыг хэлнэ"],
                        ["EAN","European Article Numbering","","","Одоогийн GS1 –ийн хуучин нэр"],
                        ["","EAN International","","","GS1 глобал оффисийн хуучин нэр"],
                        ["","EAN.UCC Company Prefix","","","GS1 компаний префиксийн хуучин нэр"],
                        ["EANCOM","","","","Электрон мессеж харилцан солилцох GS-ийн стандарт бөгөөд GS1-ийн тодорхойлогч түлхүүрийг ашигласан UN/EDIFACT стандарт мессежүүдийг ашиглан хэрэгжүүлнэ."],
                        ["EDI","Electronic Data Interchange","Электрон өгөгдөл солилцоо","",""],
                        ["EPC","Electronic Product Code","Бүтээгдэхүүний электрон код","БЭК","RFID шошго болон бусад утгаар дамжуулан бодит зүйлсийг (бараа бүтээгдэхүүн, байршил, эд хөрөнгө...) тодорхойлох систем юм. Стандарт БЭК-ийн мэдээлэл нь биет зүйлсийг тодорхойлсон БЭК–с бүрдэнэ."],
                        ["","Element String","Элемент стринг","","GS1 системийн МТ болон мэдээллийн тодорхойлох хэсгийн утгын нийлбэр юм."],
                        ["EPC tag","Electronic Product Code tag","Бүтээгдэхүүний электрон код, шошго","БЭК","RFID шошго нь БЭК-ын глобал стандарт бөгөөд БЭК- ийг агуулж байдаг."],
                        ["","EPCglobal Inc","БЭК глобал байгууллага","","Нийлүүлэлтийн гинжин хэлхээн дэх бүтээгдэхүүнийг үнэн зөв автоматаар тодорхойлох глобал стандарт болох БЭК глобал сүлжээнээс авч хэрэглэх БЭК-оор хангах ашгийн бус үйлвэрүүдээр итгэмжлэгдсэн байгууллага юм."],
                        ["","EPCglobal Network","БЭК глобал сүлжээ","","Нийлүүлэлтийн гинжин хэлхээн дэх бүтээгдэхүүний автоматаар тодорхойлж мэдээллийг солилцох боломжийг олгож байгаа технологийн иж бүрдэл юм."],
                        ["","Extension digit","Нэмэлт орон","","Хэрэглэгчээс олгосон ТСК-ын хамгийн эхний орон нь серийн дарааллын багтаамжийг нэмэгдүүлэхэд ашиглагдах орон"],
                        ["","Fixed length","Тогтмол урт","","Тэмдэгтийн элемент стрингийн мэдээллийн хэсгийг илэрхийлэхэд ашиглагдана."],
                        ["","Fixed Measure Trade Item","Тогтсон хэмжигдхүүнтэй худалдааны эд зүйлс","","Нийлүүлэлтийн гинжин хэлхээний аль ч хэсэгт зарагдах боломжтой төрөл бүрийн хэмжээ, жин, агуулга, загвар зэргээ урьдчилан тогтоож үйлдвэрлэсэн бүтээгдэхүүнийг хэлнэ."],
                        ["FNC1","Function Code 1","","",""],
                        ["GS","Global standards/solution","Глобал стандарт","",""],
                        ["GDD","Global Data Dictionary","Мэдээллийн глобал толь","","Бүх бизнесийн нэгжүүдэд хэрэглэгдэх бизнесийн нэр томьѐо, тодорхойлолтуудыг GS1 стандарт, зөвшөөрлүүдийн дагуу мэдээллэхэд ашиглагдах агуулах төхөөрөмж юм."],
                        ["GDTI","Global Document Type Identifier","Бичиг баримтын төрлийг тодорхойлох глобал дугаар","ББГД","Баримт бичгийн глобал дугаарыг тодорхойлох: GS1 тодорхойлогч түлхүүрийг оролцуулаад GS1 компаний префикс, баримтын төрөл, хяналтын орон зэрэг нь баримтыг тодорхойлоход ашиглагдана."],
                        ["GIAI","Global Individual Asset Identifier","Үндсэн үл хөдлөх хөрөнгийн глобал дугаар","ҮХХГД","Үл хөдлөх хөрөнгийг тодорхойлох GS1-ын тодорхойлогч түлхүүр."],
                        ["GLN","Global Location Number","Байршлын глобал дугаар","БГД","Хууль ѐсны аж ахуйн нэгжүүдийн бодит байршлыг тодорхойлох GS1-ын тодорхойлогч түлхүүр юм."],
                        ["GRAI","Global Returnable Asset Identifier","Дахин ашиглагдах үл хөдлөх хөрөнгийн глобал дугаар","ДАХГД","Дахин ашиглагдах үл хөдлөх хөрөнгийн GS1-ын тодорхойлогч түлхүүр"],
                        ["GSRN","Global Service Relation Number","Үйлчилгээний харилцааны глобал дугаар","ҮХГД","Үйлчилгээг хүлээн авагч болон үйлчилгээ үзүүлэгчийн хоорондох харилцааг тодорхойлох GS1-ын тодорхойлогч түлхүүр"],
                        ["GTIN","Global Trade Item Number","Худалдааны эд зүйлсийн глобал дугаар","ХЭЗГД","Худалдааны эд зүйлсийн тодорхойлох GS1-ын тодорхойлогч түлхүүр"],
                        ["GPC","Global Product Classification","Бүтээгдэхүүний глобал ангилал","",""],
                        ["","GS1 Check Digit Calculation","GS1 хяналтын оронг тооцоолох","","Мэдээллийн үнэн зөвийг баталгаажуулах хяналтыны оронг тооцоолох GS1 системийн алгоритм"],
                        ["","GS1 Company Prefix","GS1 компаний префикс","","GS1 системийн тодорхойлогч түлхүүрийн нэг хэсэг бөгөөд GS1-ийн гишүүн байгууллагаас олгосон компаний префикс болон компаний дугаараас бүрдэнэ."],
                        ["","GS1 EANCOM","","","GS1 eCom-ын нэгдэл. Электрон мэдээлэл харилцан солилцох GS1 стандарт нь интернет дээр суурилсан электрон худалдааны үр нөлөөг чиглүүлэх, и- бизнесийн бизнес мессежийн глобал хэлийг хэрэглэгчдэд хангаж байдаг"],
                        ["GS1 GS","GS1 General Specifications","GS1-ийн ерөнхий тодорхойлолтууд","","GS1-ийн тодорхойлогч түлхүүр болон зураасан код, RFID-ыг ашиглан худалдааны эд зүйлс, байршил, логистикийн нэгжүүд, үл хөдлөх хөрөнгө зэргийг автоматаар толорхойлох болон тэмдэглэгээтэй холбогдсон GS1 системийн мэдээлэл, өгөгдлийн стандартуудын ,тодорхойлолт юм."],
                        ["GS1 GO","GS1 Global Office","GS1 глобал оффис","","GS1 Системийг удирддаг Белгийн Брусельд болон АНУ-ын Принсетонд төвлөрсөн GS1ийн Гишүүн Байгууллагуудын удирдах төв байгууллага юм."],
                        ["GS1 GPC","GS1 Global Product Classification","GS1 бүтээгдэхүүний глобал ангилал","","GS1 мэдээллийг ижилсүүлэх сүлжээний /GDSN/ нэгдэл юм. Энэ нь мэдээллийг ижилсүүлэх глобал сүлжээгээр хангагдсан худалдааны эд зүйлсийн ангилал дахь шаардлагатай глобал хүрээг хангаж байдаг"],
                        ["GS1 GR","GS1 Global Registry","GS1 Глобал бүртгэл","","GS1 мэдээллийг ижилсүүлэх глобал сүлжээний нэгдэл юм. Энэ нь бүтээгдэхүүний каталог болон мэдээллийн толгой хэсэгт агуулагдсан мэдээллийн сувгийг үүсгэх заагчийн үүрэг гүйцэтгэдэг. Энэ нь мөн ижилсүүлэх тоног төхөөрөмжийн захиалга бүртгэлийг тохируулах үүргийг гүйцэтгэж байдаг."],
                        ["GS1 IK","GS1 Identification Key","GS1-ын тодорхойлогч түлхүүр","","Эрэлт нийлүүлэлтийн гинжин хэлхээн дэх тодорхойлогчийн дахин давтагдашгүй цор ганц байдлыг баталж байдаг GS1-ээр удирдагдсан тоон буюу үсгэн хэсэг юм."],
                        ["GS1 MO","GS1 Member Organisation","GS1 гишүүн байгууллага","","GS1-ийн гишүүн ба тухайн оронд GS1 системийн үйл ажиллагааг удирдан явуулах үүрэгтэй. Энэ бодлогод GS1 системийн сургалт боловсрол зэрэг зөв хэрэглээг дэмжигч компаниудыг багтааж болно."],
                        ["","GS1 Prefix","GS1 префикс","","Хоѐр буюу түүнээс дээш тоон орон агуулсан GS1 глобал оффисоос зөвшөөрөгдсөн GS1 гишүүн байгууллагаас олгогдсон дугаар юм"],
                        ["","GS1 System","GS1 систем","","GS1-ээр тодорхойлогдсон тодорхойлолт, стандарт, удирдамжууд орно."],
                        ["GS1 XML","Extensible Markup Language","","","GS1 eCom-ын нэгдэл. Энэ нь интернетэд суурилсан электрон худалдааны үр нөлөөг нэмэгдүүлэх бизнесийн глобал мессеж хэлээр хэрэглэгчдийнг хангах өргөтгөж болох тэмдэглэгээт хэлний схем юм."],
                        ["GS1-128 Bar Code Symbol","","GS1-128 зураасан кодны тэмдэгт","","код 128 нь GS1 системийн мэдээллийн бүтцэд ашиглагдах тоон тэмдэгт юм."],
                        ["GSMP","Global Standards Management Process","Глобал стандартын менежментийн үйл явц","",""],
                        ["","Human Readable Interpretation","Хүний нүдээр уншигдахуйц тайлбар","","Хүн уншиж болохуйц тэмдэгтүүд болох үсэг тоо, машинаар уншигдах зураасан кодны тэмдэгтүүд зэрэг багтана."],
                        ["","Identification number","Тодорхойлогч дугаар","","Аж ахуйн нэгжүүдийн ялгааг таних боломжтой үсэг тоогоор илэрхийлэгдсэн хэсэг"],
                        ["","Indicator","Индикатор","","ХЭЗГД 14-ийн хамгийн зүүн хэсэгт байрлах 1-9 хүртэлх орон"],
                        ["ITF","Interleaved 2 of 5","","","ITF-14 тэмдэгтийн хуучин нэр"],
                        ["ISBN","International Standard Book Numbering","Номны олон улсын стандарт дугаар","",""],
                        ["ISO","International Organisation for Standardisation","Олон улсын стандартын байгууллага.","",""],
                        ["ISSN","International Standard Serial Number","Олон улсын стандарт серийн дугаар","",""],
                        ["","Item Number/reference","Бүтээгдэхүүний дугаар","",""],
                        ["","Linear Bar Code","Шугаман зураасан код","","Нэг хэмжигдэхүүн дэх зураас болон зайгаар тэмдэглэгдсэн зураасан кодны тэмдэгт"],
                        ["LAC","Local Assigned Code","Орон нутагт оноосон код","","Хязгаарлагдмал бүс нутагт тусгай зориулалтаар хэрэглэгдэх UPC-E A зураасан кодны тэмдэгт"],
                        ["","Location Reference","Байршлын тайлбар дугаар","","Байршлын глобал дугаар доторхөөр өөр хэсгүүдийг тодорхойлох дугаар юм."],
                        ["","logistic measures","Логистикийн хэмжүүрүүд","","Гаднах хэмжээсийг тодорхойлох логистикийн нэгжүүдийн нийт жин, эзлэхүүн савалгааны материал зэргийг тодорхойлно"],
                        ["","logistic unit","Логистикийн нэгж","","Нийлүүлэлтийн гинжин хэлхээгээр удирдах шаардлагатай агуулах буюу тээвэрлэхэд бэлтгэгдсэн ямар нэг төрлийн бүтээгдэхүүн. Энэ нь ТСК-оор /SSCC/ тодорхойлогдоно"],
                        ["","Payment slip","Төлбөрийн маягт","","Төлбөрийн нөхцөл, тоо хэмжээг агуулсан төлбөрт үйлчилгээний төлбөрийн мэдэгдэл"],
                        ["POS","Point of sale","Касс","","Жижиглэн худалдааны тооцоо хийх хэлбэр бөгөөд зураасан кодны тэмдэгт сканерт уншигдах хэсэг"],
                        ["QZ","Quiet Zone","Хамгаалалтын бүс","","Зураасан кодны тэмдэгтийн эхлэлийн өмнө болон тэмдэгтийн ард байрлах цагаан зайг хэлнэ."],
                        ["","Quiet Zone Indicator","Хамгаалалтын бүсийн индикатор","","Хамгаалалтын бүсийн гадна хэсэгтэй зэрэгцсэн зураасан кодны тэмдгийн хүний нүдээр уншигдахуйц хэсэгт хэвлэгдсэн их (>) багын (<) тэмдэгт"],
                        ["RF","Radio frequency","Радио давтамж","","Радио долгионы тархацтай холбогдсон цахилгаан соронзонгийн хамрах хүрээн дотрох ямар нэг давтамж. Радио давтамжийн урсгал антентай холбогдсон үед цахилгаан соронзонгийн талбай үүсэж дараа нь зайгаар тархах боломжтой болдог"],
                        ["RFID","Radio Frequency Identification","Радио давтамжаар тодорхойлох.","","Мэдээллийн дамжуулагч технологи нь мэдээллийг цахилгаан соронзонгийн хамрах хүрээний радио давтамжийн хэмжээн дэх дохиогоор дамжуулдаг. Радио давтамжийн долгионоор тодорхойлох систем нь антен болон радио давтамжийг уншиж дамжуулдаг хүлээн авагчаас бүрддэг."],
                        ["RSS","Reduced Space Symbology","Багасгасан зайн тэмдэглэгээ","БЗТ","Зураасан кодны тэмдэглэгээний бүлэг бөгөөд RSS-14, RSS хязгаарлагдмал, RSS өргөтгөсөн болон RSS-14 давхардсан зэрэг тэмдэгтүүд юм. БЗТ-ний бүлгийн гишүүд нь дангаараа шугаман тэмдэглэгээр эсвэл 2Д холимог тэмдэглэгээний хамт хэвлэгдэх боломжтой."],
                        ["","Refund Receipt","Буцааж төлөх баримт","",""],
                        ["","Retail consumer trade item","Жижиглэнгээр худалдаалагдах худалдааны эд зүйлс","","Жижиглэн худалдааны кассаар эцсийн хэрэглэгчид худалдаалагдах худалдааны эд зүйлсийг хэлнэ. Эдгээр нь EAN/UPC тэмдэглэгээний GTIN-13, GTIN-12 эсвэл GTIN-8 кодоор тодорхойлогдсон байна."],
                        ["","RFID tag","RFID шошго","","Антенд суурилсан микро чип нь RFID уншигч руу мэдээллийг дамжуулдаг. RFID шошго нь дахин давтагдашгүй серийн дугаар ба нэмэлт мэдээллийг агуулдаг. RFID шошго нь идэвхтэй, хагас идэвхтэй, идэвхгүй гэж байдаг."],
                        ["","RSS Composite Symbology Family","БЗТ-ний холимог тэмдэглэгээний бүлэг","","БЗТ-14 холимог тэмдэглэгээний бүлэгт БЗТ-1 давхардуулсан холимог тэмдэглэгээ /Stacked Composite Symbology/, RSS-БЗТ хязгаарлагдсан холимог тэмдэглэгээ /Limited Composite Symbology/, болон БЗТ өргөтгөсөн холимог тэмдэглэгээ /Expanded Composite Symbology/ багтана."],
                        ["","RSS Expanded Bar Code Symbol","БЗТ өргөтгөсөн зураасан кодны тэмдэгт","","Зураасан кодны тэмдэгт нь кассын сканерт програмчлагдсан нэг чиглэлээр уншигдах шугаман тэмдэглэгээнд жин, хэрэглэх хугацаа зэрэг нэмэлт мэдээлэл тодотгогчийг агуулж кодолдог."],
                        ["","RSS Expanded Composite Symbology","БЗТ-ний өргөтгөсөн холимог тэмдэглэгээ","","БЗТ-ний холимог тэмдэглэгээ нь БЗТ өргөтгөсөн зураасан кодны тэмдэгтийн шугаман нэгдэлд хэрэглэгддэг"],
                        ["","RSS Omnidirectional","БЗТ олон чиглэлт","","БЗТ-ний бүлэгт багтаж байгаа тэмдэглэгээнүүд жижиглэн худалдааны кассанд олон чиглэлт сканераар уншигдахаар хийгдсэн байдаг: БЗТ-14; БЗТ-14 давхардсан олон чиглэлт; БЗТ өргөтгөсөн, БЗТ өргөтгөсөн давхардсан."],
                        ["","Scanner","Сканер","","Зураасан кодны тэмдэгтүүдийг унших электрон төхөөрөмж"],
                        ["","Serial Number","Серийн дугаар","","Бодит нэгжүүдийн хэрэглэгдэх хугацаанд нь олгосон код, дугаар үсэг юм. Ж нь: 1234569 серийн дугаартай AC-2 микроскоп, 1234569 дугаартай AC-2 микроскоп гэх мэт."],
                        ["SSCC","Serial Shipping Container Code","Тээвэрлэлтийн серийн дугаар","ТСК","Логистикийн нэгжүүдэд олгосон GS1-ын 18 оронтой тодорхойлогч түлхүүр юм."],
                        ["","Service Reference","Үйлчилгээний тайлбар дугаар","","Үйлчилгээний дугаар нь үйлчилгээ нийлүүлэгчээс олгогдоно. Үйлчилгээний дугаарын бүтэц агуулга нь тодорхой үйлчилгээг нийлүүлэгчийн сонголтоор бүрдэнэ."],
                        ["","Substrate","Доод давхарга","","Зураасан кодны тэмдэгт хэвлэгдсэн материал"],
                        ["","Symbol","Тэмдэгт","","Тэмдэгтийн нэгдэл нь тодорхой тэмдэглэгээний хамгаалалтын бүс, эхлэх болон төгсөх тэмдэгт, өгөгдлийн тэмдэглэгээнүүд хамтдаа сканерт уншигдахуйц нэмэлт тэмдэглэгээнүүдийг агуулдаг."],
                        ["","Trade item","Худалдааны эд зүйлс","","Нийлүүлэлтийн гинжин хэлхээний аль нэг цэгт үнэлэгдсэн, захиалагдсан, нэхэмжлэгдсэн, урьдчилан тодорхойлогдсон аливаа нэг бүтээгдэхүүн эсвэл үйлчилгээг хэлнэ."],
                        ["","Truncation","Тайралт","","Зураасан кодны тэмдэглэгээг тогтсон өндрийн хэмжээнээс багасгаж хэвлэх. Тайралт нь сканерт уншигдахад хүндрэл үүсгэх магадлалтай."],
                        ["","U.P.C. Prefix","U.P.C префикс","","Эхлэлийн 0 тэмдэг нь хасагдсан GS1 префиксийн тусгай дүрслэл"],
                        ["UCC","Uniform Code Council","Нэгдсэн кодын зөвлөл","","GS1 US-ын хуучин нэр"],
                        ["VMN","Variable Measure Number","Хувьсах хэмжигдэхүүний дугаар","","Энэ нь GS1-ын гишүүн байгууллагуудын тухайн орны дүрмээр тодорхойлогдоно."],
                        ["VMTI","Variable measure trade item","Хувьсах хэмжигдхүүнтэй худалдааны эд зүйлс","","Нийлүүлэлтийн гинжин хэлхээний аль нэг хэсэгт зарагдах боломжтой урьдчилан тогтоосон жин хэмжээ загвартай боловч анхнаасаа тогтсон хэмжээгүй хувьсах хэмжигдэхүүнтэй бүтээгдэхүүн юм."],
                        ["","X-dimension","Х-хэмжээс","","Зураасан кодны тэмдэгт дэх нарийн элементийн өгөгдсөн өргөн"]
                    ]}
                    bkgdColor={"#f26334"}
                />



            </div>
        </Layout>
    )
}
