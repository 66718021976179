import React, {useEffect, useState} from "react";
import {useLocation} from "@reach/router";
import Layout from "../../components/layout";
import he from "he";
import "../../styles/wordpress.scss"
import clientConfig from "../../client-config";
import axios from "axios";
import Loader from "../../images/loading.gif";
import _ from "lodash"
import dayjs from "dayjs"


export default () => {
    const [post, setPost] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const location = useLocation();

    const wordPressSiteURL = clientConfig.siteUrl;
    const url1 = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/gepir-service?content=false&media=false&per_page=1`;
    useEffect(() => {
        setLoading(true);
        axios.get(url1)
            .then(res1 => {
                if (res1.data.length) {
                    const id = res1.data[0]["id"];
                    const url = `${wordPressSiteURL}/wp-json/wp/v2/posts/${id}`;
                    axios.get(url)
                        .then(res => {
                             setLoading(false);
                             setPost(res.data)
                        }).catch(err => {
                            setLoading(false);
                            setError(err.message);
                    });

                } else {
                    setLoading(false);
                    setError("No category found");
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            });

        // axios.get(url)
        //     .then(res => {
        //         if (res.data.length) {
        //             setLoading(false);
        //             setPost(res.data[0])
        //         } else {
        //             setLoading(false);
        //             setError("Мэдээ олдсонгүй!");
        //         }
        //     })
        //     .catch(err => {
        //         setLoading(false);
        //         setError(err.message);
        //     });

    }, []);

    //const post = data.wordpressPost

    let showDate = true;
    if (location.pathname.startsWith('/a/')) {
        showDate = false;
    }
    return (
        <Layout aPageProp={location.pathname}>
            {!_.isEmpty(post) ? (
            <div>
            <h1>{post.title["rendered"]}</h1>
            {showDate &&
            <div>
                <small>{dayjs(post.date).format("YYYY-MM-DD")}</small>
                <br/><br/>
            </div>
            }
            <div className="entry-content" dangerouslySetInnerHTML={{__html: post.content["rendered"]}}/>
            </div>
            )  :''}
            {loading && <img className="loader" src={Loader} alt="Loader"/>}
        </Layout>
    )
}
