

import React from "react";
import "./Header3.scss";

const Header3 = ({title}) => {
    return (
        <div className="header3">{title}</div>
    )
}

export default Header3;