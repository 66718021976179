import React, {Component, useEffect, useState} from "react";
import FooterList from "../../components/footerList";
import Navigation from "../../components/navigation";
import HeaderTop from "../../components/headerTop";
import "./types.scss";
import type1 from "../../images/GS1_type_1.jpg";
import type2 from "../../images/GS1_type_2.jpg";
import type3 from "../../images/GS1_type_3.jpg";
import type4 from "../../images/GS1_type_4.jpg";
import type5 from "../../images/GS1_type_5.jpg";
import type6 from "../../images/GS1_type_6.jpg";
import type7 from "../../images/GS1_type_7.jpg";
import type8 from "../../images/GS1_type_8.jpg";
import type9 from "../../images/GS1_type_9.jpg";
import type10 from "../../images/GS1_type_10.jpg";
import type11 from "../../images/GS1_type_11.jpg";
import type12 from "../../images/GS1_type_12.jpg";
import type13 from "../../images/GS1_type_13.jpg";
import type14 from "../../images/GS1_type_14.jpg";
import type15 from "../../images/GS1_type_15.jpg";
import type16 from "../../images/GS1_type_16.jpg";



export default ({location}) => {

    return (
        <div className="toolkit">

            <header>
                <HeaderTop/>
                <Navigation  apageprop={location.pathname} />
            </header>

            <div class="container site-content flush-outside">
                <div class="col-xs-12" style={{margin: "10px"}}>
                    <h2>Зураасан кодны төрлүүд</h2>

                    <p>
                    GS1 систем нь GS1-ийн гишүүд, хэрэглэгчдийн хэрэгцээ, шаардлагад тулгуурлан урт, богино, тоон болон дүрсэн янз бүрийн тэмдэглэгээ бүхий зураасан кодны стандартуудыг хөгжүүлж олгодог. Тухайлбал: GS1-8, GS1-13, GS1 датабар, GS1-128, ITF-14, GS1 датаматрикс, GS1 QR код, холимог тэмдэглэгээ зэрэг нь GS1-ийн үндсэн стандарт кодууд ба эдгээрийг дүрслэн үзүүлбэл:
                    </p>

                    <h3>Зураасан кодны тэмдэглэгээнүүд</h3>
                    <table className="tb3">
                        <thead>
                        <tr>
                            <th>GS1</th>
                            <th>Стандарт, хэрэглэгдэх хүрээ</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <img src={type1}/>
                            </td>
                            <td>
                                <ul>
                                    <li>UPC-A гэсэн тэмдэглэгээ</li>
                                    <li>12 орон бүхий хүний нүдэнд уншигдахуйц тоон мэдээллийг багтаадаг.</li>
                                    <li>Олон чиглэлд уншигддаг.</li>
                                    <li>ПОС дээр ихэвчлэн ашиглагддаг.</li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <img src={type2}/>
                            </td>
                            <td>
                                <ul>
                                    <li>GS1-13 тэмдэглэгээ</li>
                                    <li>13 орон бүхий тоон мэдээлэлагуулдаг.</li>
                                    <li>Олон чиглэлд уншигдана.</li>
                                    <li>ПОС буюу жижиглэн худалдааны борлуулалтын цэг дээр их хэмжээгээр уншигддаг кодны
                                        төрөл.
                                    </li>
                                    <li>Стандарт хэмжээнээс илүү томоор хэвлэх боломжтой учраас бөөний худалдаа,
                                        логистикийн үйл ажиллагаанд өргөнөөр ашиглагддаг.
                                    </li>
                                    <li>Хязгаарлагдмал хэрэглээ буюу дотоод дугаарлалт, тогтмол бус хэмжигдэхүүнтэй
                                        худалдааны эд зүйлсийг тодорхойлох зэргээр ашиглагддаг.
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <img src={type3}/>
                            </td>
                            <td>
                                <ul>
                                    <li>UPC-E тэмдэглэгээ</li>
                                    <li>12 орон бүхий тоон мэдээлэл агуулдаг, журмын дагуу 0-ийн араар таслана.</li>
                                    <li>Олон чиглэлд уншигддаг.</li>
                                    <li>ПОС дээр өргөнөөр ашиглагддаг.</li>
                                </ul>
                            </td>
                        </tr>


                        <tr>
                            <td>
                                <img src={type4}/>
                            </td>
                            <td>
                                <ul>
                                    <li>EAN-8 хувилбар</li>
                                    <li>8 орон бүхий хүний нүдэнд уншигдахуйц мэдээлэл агуулдаг.</li>
                                    <li>Олон чиглэлд уншигддаг.</li>
                                    <li>ПОС дээр өргөнөөр ашиглагддаг.</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                            <th>GS1 Датабар</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <img src={type5}/>
                            </td>
                            <td>
                                <ul>
                                    <li>GS1 датабар олон чиглэлд уншигддаг.</li>
                                    <li>14 орон бүхий тоон мэдээлэл агуулдаг.</li>
                                    <li>ХЭЗГД - 8,12, 13, 14-ыг багтаах боломжтой.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src={type6}/>
                            </td>
                            <td>
                                <ul>
                                    <li>GS1 давхар датабар</li>
                                    <li>Олон чиглэлд уншигдах боломжтой.</li>
                                    <li>14 орон бүхий тоон мэдээлэл багтана.</li>
                                    <li>ХЭЗГД - 8,12,13,14 багтаах боломжтой.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src={type7}/>
                            </td>
                            <td>
                                <ul>
                                    <li>GS1 өргөтгөсөн датабар</li>
                                    <li>74 хүртэлх тоон/ 41 хүртэлх үсэг болон тоон мэдээлэл агуулах боломжтой.</li>
                                    <li>GS1-ийн бүх тодорхойлогч түлхүүрүүд болон мэдээллийг тодотгогч /МТ/-той хамт
                                        хэрэглэдэг.
                                    </li>
                                    <li>Олон чиглэлд уншигддаг.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src={type8}/>
                            </td>
                            <td>
                                <ul>
                                    <li>GS1 өргөтгөсөн, давхар датабар</li>
                                    <li>Хамгийн ихдээ 74 хүртэлх тоон/ 41 хүртэлх үсэг болон тоон мэдээлэл агуулах
                                        боломжтой.
                                    </li>
                                    <li>GS1-ийн бүх төрлийн тодорхойлогч түлхүүрүүд болон МТ-той хамт хэрэглэж болно.
                                    </li>
                                    <li>Олон чиглэлд уншигддаг.</li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <img src={type9}/>
                            </td>
                            <td>
                                <ul>
                                    <li>GS1 хязгаарлагдмал датабар</li>

                                    <li>14 орон бүхий тоон мэдээлэлтэй.</li>

                                    <li>ХЭЗГД - 8, 12, 13, 14-ыг багтаасан байдаг.</li>

                                    <li>0 юмуу 1-ээр эхэлсэн байдаг.</li>

                                    <li>Олон чиглэлд уншигдах боломжгүй.</li>

                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src={type10}/>
                            </td>
                            <td>
                                <ul>
                                    <li>GS1 тайрсан датабар</li>

                                    <li>14 орон бүхий тоо мэдээлэлтэй.</li>

                                    <li>ХЭЗГД - 8,12,13,14-ыг оруулах боломжтой.</li>

                                    <li>Олон чиглэлд уншигдах боломжтгүй.</li>

                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src={type11}/>
                            </td>
                            <td>
                                <ul>
                                    <li>GS1 давхар датабар</li>

                                    <li>14 орон бүхий тоон мэдээлэлтэй.</li>

                                    <li>ХЭЗГД - 8,12,13,14 оруулах боломжтой.</li>

                                    <li>Олон чиглэлд уншигдах боломжгүй.</li>

                                    <li>Эдгээр төрлийн кодуудыг ПОС буюу жижиглэн худалдааны цэг дээр ашигладаг ба
                                        хэмжээний хувьд GS1-13-аас жижиг. Энэ төрлийн кодоор дамжуулан серийн дугаар,
                                        хүчинтэй байх хугацаа, хугацаа дуусах өдөр, бач/лотын дугаар зэрэг нэмэлт
                                        мэдээллүүдийг кодолдог.
                                    </li>

                                    <li>GS1-ын датабар төрлийн эдгээр тэмдэгтүүдийг ПОС дээр бус эрүүл мэндийн салбарт
                                        хэрэглэхийг төв байгууллагаас зөвлөж стандартыг нь баталсан.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                            <th>GS1-128</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <img src={type12}/>
                            </td>
                            <td>
                                <ul>

                                    <li>GS1-128 тэмдэглэгээ</li>

                                    <li>48 хүртэлх үсэг, тоо болон дүрсэн мэдээллийг кодлох боломжтой.</li>

                                    <li>МТ-той хамт хэрэглэдэг.</li>

                                    <li>Олон чиглэлт бус зөвхөн нэг чиглэлд уншигддаг.</li>

                                    <li>Тус тэмдэглэгээг GS1-ийн бүх түлхүүрүүд, нэмэлт, онцлог мэдээллүүдийг агуулах
                                        боломжтой ч гэсэн ПОС дээрх эд зүйлсийг таниулахад ашиглагддаггүй.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>

                        <thead>
                        <tr>
                            <th>ITF-14</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <img src={type13}/>
                            </td>
                            <td>
                                <ul>
                                    <li>ITF-14 тэмдэглэгээ нь 14 тоо бүхий зураасан тэмдэглэгээ юм.</li>

                                    <li>ХЭЗГД -12, 13, 14-ыг багтаасан байдаг.</li>

                                    <li>Олон чиглэлд уншигдах боломжтой.</li>

                                    <li>ITF-14 зураасан код нь зөвхөн Худалдааны Эд Зүйлсийн Глобал Дугаар /ХЭЗГД/
                                        дугаар буюу GS1-13 тэмдэглэгээг агуулж байдаг.
                                    </li>

                                    <li>Барзгар гадаргуу бүхий цаасан хайрцаг дээр шууд хэвлэж болно. Харин ПОС дээр
                                        ашигладаггүй.
                                    </li>

                                    <li>Бөөний худалдаа, агуулах, тээвэрлэлтэнд зориулж бөөнөөр савласан бараа,
                                        бүтээгдэхүүний сав баглаа, боодол дээр ихэвчлэн ашигладаг.
                                    </li>

                                </ul>
                            </td>
                        </tr>
                        </tbody>


                        <thead>
                        <tr>
                            <th>GS1 Датаматрикс</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <img src={type14}/>
                            </td>
                            <td>
                                <ul>
                                    <li>GS1 датаматрикс (хувилбар ECC 200)</li>

                                    <li>3116 хүртэлх тоон,</li>

                                    <li>2335 хүртэлх үсгэн мэдээлэл оруулах оруулах боломжтой.</li>

                                    <li>МТ-той хамт хэрэглэж болдог.</li>

                                    <li>Цорын ганц GS1-ийг тодотгогч</li>

                                    <li>GS1 датаматрикс буюу “2 хэмжээст матрикс” тэмдэгт гэж нэрлэдэг.</li>

                                    <li>Тус тэмдэгт нь камерт суурилсан уншигч шаарддаг тул өнөө үед ПОС зэрэг шууд
                                        тэмдэглэгдэх хэсэгт бус эрүүл мэндийн салбарт гол төлөв ашиглаж байна.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>


                        <thead>
                        <tr>
                            <th>GS1 QR код</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <img src={type15}/>
                            </td>
                            <td>
                                <ul>
                                    <li>GS1 QR код нь ISO/IEC QR код 2005 – ын олонлог</li>

                                    <li>7089 хүртэлх тоон,</li>

                                        <li>4296 хүртэлх үсгэн,</li>

                                        <li>2953 хүртэлх байт мэдээллийг тус тус агуулах боломжтой.</li>

                                        <li>МТ-той хамт хэрэглэж болдог.</li>

                                        <li>GS1 QR код нь "2 хэмжээст матрикс" тэмдэгт юм.</li>

                                        <li>Энэ код нь камерт суурилсан уншигч шаарддаг учраас одоогоор гар утасны
                                            төхөөрөмжид дүрс уншигч программтай тохиолдолд ашигладаг.
                                        </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>


                        <thead>
                        <tr>
                            <th>Холимог тэмдэглэгээ</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <img src={type16}/>
                            </td>
                            <td>
                                <ul>
                                    <li>“Холимог тэмдэглэгээ” гэдэг нь “2 хэмжээст шугаман тэмдэг” юм.</li>

                                    <li>Энэ код нь GS1-128 болон багасгасан зайн тэмдэглэгээтэй хамт хэрэглэгддэг
                                        шугаман зураасан код учраас “холимог” гэж нэрлэдэг.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>

            </div>

            <FooterList />
        </div>
    )
    }
