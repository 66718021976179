import React from "react";

import {useLocation} from "@reach/router";

import Layout from "../../components/layout.js";
import Header3 from "../../components/Header3";
import gs1_8_1 from "../../images/gs1-8-1.jpg";
import gs1_8_2 from "../../images/gs1-8-2.jpg";
import gs1_13_1 from "../../images/gs1-13-1.jpg";
import gs1_13_2 from "../../images/gs1-13-2.jpg";
import gs1_13_3 from "../../images/gs1-13-3.jpg";
import gs1_13_4 from "../../images/gs1-13-4.jpg";
import gs1_14_1 from "../../images/gs1-14-1.jpg";
import gs1_14_2 from "../../images/gs1-14-2.jpg";

import gs1_128_1 from "../../images/gs1-128-1.jpg";
import code39 from "../../images/code39.jpg";
import code93 from "../../images/code93.jpg";

export default () => {

    const location = useLocation();
    return (
        <Layout aPageProp={location.pathname}>
            <div style={{marginLeft: "10px"}}>

                <h2>1 хэмжээст зураасан код</h2>

                <p>Дэлхий нийтээрээ ямар ч бизнесийн болоод төрийн, төрийн бус байгууллага том, жижиг, хаана, ямар үйл
                    ажиллагаа явуулж байгаагаасаа үл хамааран нэг стандартыг, нэг шийдлийг гарган хамтран ажиллаж уг
                    системийг хэрэгжүүлэх боломжтой юм.</p>

                <p>GS1-ын энэхүү глобал стандарт нь нийлүүлэлтийн хэлхээнд оролцогч талуудын талаарх мэдээлэл болон
                    худалдааны эд зүйлс, бараа, үйлчилгээ, логистикийн нэгж, ачилтын нэгж, хөдлөх болон үл хөдлөх
                    хөрөнгө, баримт бичиг, худалдаа, үйлчилгээнд оролцогч талуудын байршил зэргийг дахин
                    давтагдашгүйгээр зураасан кодны дараах тэмдэглэгээнүүдийн тусламжтайгаар тодорхойлж байдаг.
                    Эдгээрийг цогцоор нь GS1 систем гэж нэрлэдэг.</p>

                <p>GS1 системийн стандартуудын нэг болох нэг хэмжээст зураасан кодны төрлүүд болон Монгол улсад хамгийн
                    өргөн хэрэглэгддэг зураасан код түүний хэрэглээ, стандартуудын талаар танилцуулж байна.</p>

                <Header3 title="GS1-8" style={{marginBottom: "20px"}}/>

                <p>GS1-8 зураасан кодны тэмдэглэгээ нь 8 оронтой, гоо сайхан болон бичиг хэрэгслийн бүтээгдэхүүн болох
                    үзэг, бал, бохь, тамхи, зэрэг жижиг овор хэмжээ, савлагаа бүхий бүтээгдэхүүн, тэдгээрийн сав баглаа
                    боодол дээр хамгийн түгээмэл хэрэглэгддэг.</p>

                <table style={{marginTop: "30px"}}>
                    <tr>
                        <td>
                            <img style={{marginRight: "30px", width: "90px"}} src={gs1_8_1}/>
                        </td>
                        <td>
                            <img src={gs1_8_2} style={{marginRight: "30px", marginBottom: "20px"}}/>
                        </td>
                        <td>

                            GS1-8 тэмдэглэгээний тохиромжит хэмжээ

                            Хамгийн бага хэмжээ: 21.38 мм х 17 мм

                            Хамгийн том хэмжээ: 53.46 мм х 43 мм

                            Тохиромжит хэмжээ: 26.73 мм х 21 мм
                        </td>
                    </tr>
                </table>


                <Header3 title="GS1-13"/>


                <p>GS1-13 зураасан кодны тэмдэглэгээ нь бөөний болон жижиглэнгийн худалдаанд өргөн хэрэглэгддэг, хүний
                    нүдэнд харагдахуйц, уншигдахуйц тоон мэдээлэл бүхий 13 оронтой кодны төрөл.</p>
                <table style={{marginTop: "30px", marginBottom: "30px"}}>
                    <tr>
                        <td>
                            <img src={gs1_13_1} style={{marginRight: "30px", marginBottom: "0px", width: "90px"}}/>
                        </td>
                        <td>
                            <img src={gs1_13_3} style={{marginRight: "30px", marginBottom: "20px"}}/>
                        </td>
                        <td>

                            GS1-13 тэмдэглэгээний тохиромжит хэмжээ

                            Хамгийн бага хэмжээ: 29.83 мм х 21 мм

                            Хамгийн том хэмжээ: 74.58 мм х 46 мм

                            Тохиромжит хэмжээ: 37.29 мм х 26 мм
                        </td>
                    </tr>
                </table>

                GS1-13 кодны бүтэц, дугаар нь дөрвөн бүсээс бүрддэг. Үүнд:
                <ul>
                    <li>Тухайн улсын код /төв байгууллагаас манай улсад өгсөн префикс ба “GS1 Монгол”нийгэмлэгээс 865
                        бүхий кодыг олгодог/
                    </li>

                    <li>Үйлдвэрлэгчийн код /дугаарлалтын системийн дагуу тухайн байгууллагад олгогдсон цор ганц дахин
                        давтагдашгүй код ба тухайн компаний үйлдвэрлэсэн бүх бүтээгдэхүүн нэг ижил үйлдвэрлэгчийн кодтой
                        байна/
                    </li>

                    <li><p>Бүтээгдэхүүний код /үйлдвэрлэгчийн кодыг дагаж үүсэх давтагдашгүй дугаар/</p></li>

                    <li><p>Хяналтын орон /энэ орон нь зураасан кодны орнуудаас тооцоологдож үүсдэг, тухайн зураасан код
                        сканерт зөв алдаагүй уншигдах байдлыг баталгаажуулж өгдөг/</p></li>
                </ul>
                <img src={gs1_13_2} style={{marginBottom: "20px"}}/>
                <br/>

                <img src={gs1_13_4} style={{marginTop: "20px", marginBottom: "0px"}}/>


                <Header3 title="GS1-14"/>


                <p>GS1-14 тэмдэглэгээ нь багцын код бөгөөд тухайн нэгж бүтээгдэхүүнийг өөр өөр хэмжээгээр багц болгон
                    савалж дахин давтагдахгүй GS1-14-өөр тодорхойлдог. Тус тэмдэглэгээг жижиглэн худалдаалах цэг дээр
                    бус зөвхөн бөөний худалдаа, агуулах, түгээлт, тээвэрлэлтийн үйл ажиллагаанд багцын бүртгэл тооцоо,
                    хяналт хийхэд ашигладаг. GS1-14 тэмдэглэгээ нь өргөн хар хүрээгээр хүрээлэгддэг ба үүнийг
                    хамгаалалтын зураас гэж нэрлэдэг.</p>

                <table style={{marginTop: "30px", marginBottom: "30px"}}>
                    <tr>
                        <td><img src={gs1_14_1} style={{marginRight: "30px", marginBottom: "0px", width: "100px"}}/>
                        </td>
                        <td>
                            <img src={gs1_14_2} style={{marginRight: "20px", marginBottom: "20px"}}/>
                        </td>
                        <td>

                            GS1-14 тэмдэглэгээний тохиромжит хэмжээ

                            Хамгийн бага хэмжээ: 71.40 мм х 12.70 мм

                            Хамгийн том хэмжээ: 142.75 мм х 32.00 мм

                            Тохиромжит хэмжээ: 142.75 мм х 32.00 мм
                        </td>
                    </tr>
                </table>

                <Header3 title="GS1-128"/>

                <img src={gs1_128_1} style={{marginBottom: "20px"}}/>

                <p>GS1-128 тэмдэглэгээ нь мэдээллийг тодотгогч /МТ/-ийн тусламжтайгаар 70 хүртэлх үсэг болон тоон
                    мэдээлэл агуулах боломжтой. Тус кодоор логистикийн нэгж, харилцагчийн карт, хөдлөх болон үл хөдлөх
                    хөрөнгө, баримт бичиг, үйлдвэрлэлийн дотоод үйл ажиллагаа, тээвэрлэлт, чингэлгийн серийн дугаар,
                    бүтээгдэхүүний дуусах хугацаа, үйлдвэрлэсэн огноо, сер, лотын дугаар зэргийг кодлоход ашигладаг.</p>

                <p>МТ гэдэг нь хаалтанд буй 2-4 оронтой тоо бөгөөд арын мэдээллээ тодотгож байдаг. Жишээ нь: дээрх кодны
                    хувьд (01) нь тухайн бүтээгдэхүүний дахин давтагдахгүй зураасан кодыг, (30) нь савлалтын хэмжээ,
                    (10) нь тухайн багц буюу логистикийн лот/багцын дугаарыг илэрхийлнэ. GS1 системд 120 гаруй нэр,
                    төрлийн мэдээллийг тодотгогч буюу /Application indentifier-AI/ идэвхтэй хэрэглэгддэг.</p>


                <Header3 title="Код 39"/>

                <img src={code39} style={{marginBottom: "20px"}}/>

                <p>Код 39 тэмдэглэгээ нь 43 төрлийн тэмдэгтээр тодорхойлогддог өөрөөр хэлбэл А-Z хүртэлх үсэг, 0-9
                    хүртэлх тоо мөн хэд хэдэн тусгай тэмдэгтүүдээс (-, ., $, /, +, %) бүрддэг. Нэмэлт тэмдэг болох
                    ('*')-ээр эхлэл төгсгөлийн хязгаарийг тодорхойлоход хэрэглэдэг. Эдгээр однууд нь (*) кодны доод
                    бичвэрийн хэсэгт харагддаггүй ч код үүсгэхэд автоматаар үүсдэг.</p>

                <p>Энэхүү код нь өөртөө хяналтын орныг агуулдаггүй боловч программын хэл рүү орчуулагдан өөрөө
                    шалгагдахаар шийдэгдсэн байдаг бөгөөд өөр тэмдэгтээр солигдох боломжгүй. Код 39 тэмдэглэгээний сул
                    тал нь мэдээллийн нягтрал багатай. Жишээ нь код 128 аас илүү код 39 өөр мэдээллийг кодлоход их зайг
                    шаардаж байдаг. Энэ нь жижиг хэмжээтэй барааг код 39 баркодоор шошголох боломжгүй юм.</p>


                <Header3 title="Код 93"/>

                <img src={code93} style={{marginBottom: "20px"}}/>


                <p>Код 93 тэмдэглэгээ нь код 39-ийн дараагийн сайжруулсан хувилбар бөгөөд үсэг тоон мэдээлэл агуулах
                    боломжтой. Мөн *, -, $, %, , ., /, + зэрэг тэмдэглэгээнүүдийг агуулах боломжтой.</p>

            </div>
        </Layout>
    )
}
