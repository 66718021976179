import React, {Component, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import FooterList from "../../components/footerList";
import Navigation from "../../components/navigation";
import HeaderTop from "../../components/headerTop";
import "./keys.scss";
import img from "../../images/GS1-ID-keys-hevtee-1.jpg"
import {useLocation} from "@reach/router";
import Layout from "../../components/layout.js";
import TableContainer from "../../components/TableContainer.js";


export default () => {

    const location = useLocation();
    return (
        <Layout aPageProp={location.pathname}>
            <div>

                <h2>Дэлхийн улс орнуудын GS1-ын угтвар дугаар /Префикс/-ын жагсаалт</h2>

                <p>GS1-ын төв байгууллагаас гишүүн орон бүрт дахин давтагдашгүй гурван оронтой угтвар дугаарыг олгодог ба нэг улсад хэд хэдэн угтвар дугаар байх боломжтой. Тухайлбал: Франц улсын хувьд 300-379 гэх мэт.</p>


                <TableContainer
                    titles={[
                        "Префикс",
                        "Улсын нэр",
                        "Префикс",
                        "Улсын нэр"
                    ]}
                    rows={[["000 - 019",	"GS1 АНУ", "624",	"GS1 Ливи"],
                        ["020 - 029",	"Дотоод дугаарлалт",	"625",	"GS1 Иордани"],
                        ["030 - 039",	"GS1 АНУ",	"626",	"GS1 Иран"],
                        ["040 - 049",	"Тодорхой бүс нутагт хэрэглэгдэнэ.",	"627",	"GS1 Кувейт"],
                        ["050 - 059",	"Купон",	"628",	"GS1 Саудын Араб"],
                        ["060 - 139",	"GS1 АНУ",	"629",	"GS1 Эмират"],
                        ["200 - 299",	"Тодорхой бүс нутагт хэрэглэгдэнэ.",	"640 - 649",	"GS1 Финланд"],
                        ["300 - 379",	"GS1 Франц",	"690 - 695",	"GS1 Хятад"],
                        ["380",	"GS1 Болгар",	"700 - 709",	"GS1 Норвеги"],
                        ["383",	"GS1 Словени",	"729",	"GS1 Израйль"],
                        ["385",	"GS1 Хорват",	"730 - 739",	"GS1 Швед"],
                        ["387",	"GS1 Босни",	"740",	"GS1 Гватемал"],
                        ["389",	"GS1 Монтенегро",	"741",	"GS1 Ел Салвадор"],
                        ["400 - 440",	"GS1 Герман",	"742",	"GS1 Гондурас"],
                        ["450 - 459, 490 - 499",	"GS1 Япон",	"743",	"GS1 Никарагуа"],
                        ["460 - 469",	"GS1 Орос",	"744",	"GS1 Коста Рика"],
                        ["470",	"GS1 Киргизстан",	"745",	"GS1 Панам"],
                        ["471",	"GS1 Тайвань",	"746",	"GS1 Доминикан"],
                        ["474",	"GS1 Эстон",	"750",	"GS1 Мексик"],
                        ["475",	"GS1 Латви",	"754 - 755",	"GS1 Канад"],
                        ["476",	"GS1 Азербайжан", "759", "GS1 Венесуэль"],
                        ["477",	"GS1 Литви",	"760 - 769",	"GS1 Швейцарь"],
                        ["478",	"GS1 Узбекистан",	"770 - 771",	"GS1 Колумб"],
                        ["479",	"GS1 Шри Лан",	"773",	"GS1 Уругвай"],
                        ["480",	"GS1 Филлипин",	"775",	"GS1 Перу"],
                        ["481",	"GS1 Белорус",	"777",	"GS1 Болив"],
                        ["482",	"GS1 Украйн",	"779",	"GS1 Аргентин"],
                        ["484",	"GS1 Молдав",	"780",	"GS1 Чили"],
                        ["485",	"GS1 Армен",	"784",	"GS1 Парагвай"],
                        ["486",	"GS1 Гүрж",	"786",	"GS1 Эквадор"],
                        ["487",	"GS1 Казакстан",	"789 - 790",	"GS1 Бразил"],
                        ["488",	"GS1 Тажикистан",	"800 - 839",	"GS1 Итал"],
                        ["489",	"GS1 Хонг Конг",	"840 - 849",	"GS1 Испани"],
                        ["500 - 509",	"GS1 Их Британи",	"850",	"GS1 Куба"],
                        ["520",	"GS1 Төв оффис",	"858",	"GS1 Словак"],
                        ["521",	"GS1 Грек",	"859",	"GS1 Чех"],
                        ["528",	"GS1 Ливан",	"860",	"GS1 Серби"],
                        ["529",	"GS1 Кипр",	"865",	"GS1 Монгол"],
                        ["530",	"GS1 Албани",	"867",	"GS1 Хойд Солонгос"],
                        ["531",	"GS1 Макадон",	"868 - 869",	"GS1 Турк"],
                        ["535",	"GS1 Малта",	"870 - 879",	"GS1 Нидерланд"],
                        ["539",	"GS1 Ирланд",	"880",	"GS1 Өмнөд Солонгос"],
                        ["540 - 549",	"GS1 Бельги Люксенбург",	"884",	"GS1 Камбож"],
                        ["560",	"GS1 Португал",	"885",	"GS1 Тайланд"],
                        ["569",	"GS1Исланд",	"888",	"GS1 Сингапур"],
                        ["570 - 579",	"GS1 Дани",	"890",	"GS1 Энэтхэг"],
                        ["590",	"GS1 Польш",	"893",	"GS1 Вьетнам"],
                        ["594",	"GS1 Румын",	"896",	"GS1 Пакистан"],
                        ["599",	"GS1 Унгар",	"899",	"GS1 Индонез"],
                        ["600 - 601",	"GS1 Өмнөд Африк",	"900 - 919",	"GS1 Австри"],
                        ["603",	"GS1 Гана",	"930 - 939",	"GS1 Австрали"],
                        ["604",	"GS1 Сенегал",	"940 - 949",	"GS1 Шинэ Зеланд"],
                        ["608",	"GS1 Бахрайн",	"950",	"GS1 Төв оффис"],
                        ["609",	"GS1 Мавритан",	"951",	"GS1 Төв оффис"],
                        ["611",	"GS1 Мороко",	"955",	"GS1 Малайзи"],
                        ["613",	"GS1 Алжир",	"958",	"GS1 Макао"],
                        ["615",	"GS1 Негр",	"960-969",	"Төв оффис"],
                        ["616",	"GS1 Кени",	"977",	"Цуврал хэвлэл (ISSN)"],
                        ["618",	"GS1 Айвори Коаст",	"978 - 979",	"Ном (ISBN)"],
                        ["619",	"GS1 Тунис",	"980",	"Буцааж төлөх баримт"],
                        ["621",	"GS1 Сири",	"981 - 983",	"Нийтлэг валютын купон"],
                        ["622",	"GS1 Египет",	"990 - 999",	"Купон"]]}
                    bkgdColor={"#f26334"}
                />

            </div>
        </Layout>
    )
}
