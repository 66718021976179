import React, {Component, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import FooterList from "../components/footerList";
import Navigation from "../components/navigation";
import HeaderTop from "../components/headerTop";

import ReCAPTCHA from "react-google-recaptcha";
import {Link, useLocation} from "@reach/router";

const recaptchaRef = React.createRef();

export default () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    const [phone, setPhone] = useState("")
    const [note, setNote] = useState("")

    const location = useLocation();

    const handleChangeName = (e) => {
        setName(e.target.value);
    };
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    };
    const handleChangeCompany = (e) => {
        setCompany(e.target.value);
    };
    const handleChangePhone = (e) => {
        setPhone(e.target.value);
    };
    const handleChangeNote = (e) => {
        setNote(e.target.value);
    };

    const handleSend = () => {
        recaptchaRef.current.execute();

        if (!name) {
            alert("Хоосон байж болохгүй!")
            return;
        }
        if (!email) {
            alert("Хоосон байж болохгүй!")
            return;
        }

        // Build formData object.
        let formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('company', company);
        formData.append('note', note);


        fetch("http://localhost:8090/v1.0/contact",
            {
                body: formData,
                method: "post"
            });


    };
    return (
        <div className="toolkit">
            <Helmet>
                <link rel="stylesheet" type="text/css" href="//cloud.typography.com/6247692/752844/css/fonts.css"/>
            </Helmet>

            <header>
                <HeaderTop/>
                <Navigation  apageprop={location.pathname}/>
            </header>

            <div class="container site-content flush-outside">
                <div class="col-xs-12 col-md-9">
                    <form method="post" action="https://getform.io/f/371b5e34-c8c6-4f7f-9531-7cc111993c2c" style={{padding: "40px"}}>

                        <h1>Зурвас Илгээх</h1>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6LcLvcgUAAAAALkXCs2XoeemMCTZCogo-U5bY0Qf"
                        />

                        <div className="form-group">
                            <label for="name">Нэр</label>
                            <input id="name" className="form-control" type="text" name="name" value={name}
                                   onChange={(e) => handleChangeName(e)} placeholder="Нэр оруулах"/>
                        </div>
                        <div className="form-group">
                            <label for="email">Е-Мэйл</label>
                            <input id="email" className="form-control" type="email" name="email" value={email}
                                   onChange={(e) => handleChangeEmail(e)} placeholder="Е-Мэйл оруулах"/>
                        </div>
                        <div className="form-group">
                            <label for="company">Компани</label>
                            <input id="company" className="form-control" type="text" name="company" value={company}
                                   onChange={(e) => handleChangeCompany(e)} placeholder="Компани оруулах"/>
                        </div>
                        <div className="form-group">
                            <label for="phone">Утас</label>
                            <input id="phone" className="form-control" type="text" name="phone" value={phone}
                                   onChange={(e) => handleChangePhone(e)} placeholder="Утас оруулах"/>
                        </div>
                        <div className="form-group">
                            <label for="note">Зурвас</label>
                            <textarea id="note" className="form-control" value={note} name="note" rows="10"
                                      onChange={(e) => handleChangeNote(e)} placeholder="Зурвас оруулах"/>
                        </div>

                        <button className="btn btn-primary" type="submit" onClick={(e) => handleSend()}>
                            Илгээх
                        </button>


                    </form>
                </div>
                <div class="col-sm-3 hidden-xs hidden-sm">
                    <nav class="local-navigation structure-navigation-other">
                        <ul class="list-group">
                            <li class="list-group-item item-l1"><a href="/">GS1 Монгол</a></li>
                            <ul class="list-group group-l2">
                                <li class="list-group-item item-l2"><Link to="/ab/aboutus">Бидний тухай</Link></li>
                                <li class="list-group-item item-l2 item-l2-active"><a href="#0">Асуултууд</a></li>
                                <ul class="list-group group-l3">
                                    <li class="list-group-item item-l3"><Link to="/asuult/байнга-тавигддаг-асуултууд">Байнга тавигддаг</Link></li>
                                    <li class="list-group-item item-l3"><Link to="/asuult/тээвэр-логистик">Тээвэр логистик</Link></li>
                                    <li class="list-group-item item-l3"><Link to="/asuult/gs1-систем">GS1 систем</Link></li>
                                </ul>
                                <li class="list-group-item item-l2">Мэдээ Мэдээлэл</li>
                                <ul class="list-group group-l3">
                                    <li class="list-group-item item-l3"><Link to="/news">Мэдээ Мэдээлэл</Link></li>
                                    <li class="list-group-item item-l3"><Link to="/events">Арга Хэмжээ</Link></li>
                                    <li class="list-group-item item-l3"><Link to="/newsletter">Электрон сонин</Link></li>
                                </ul>
                                <li class="list-group-item item-l2">Үйлчилгээ</li>
                                <ul class="list-group group-l3">
                                    <li class="list-group-item item-l3"><a href="https://app.gs1mongolia.org/company-self-create">Шинээр элсэх</a></li>
                                    <li class="list-group-item item-l3"><a href="https://members.gs1mongolia.org">Гишүүдийн булан</a></li>
                                    <li class="list-group-item item-l3"><a href="https://www.gs1.org/services/check-digit-calculator">Хяналтын орон</a></li>
                                </ul>
                            </ul>
                        </ul>
                    </nav>
                </div>
            </div>

            <FooterList />
        </div>
    )
}
