import React, {Component, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import FooterList from "../../components/footerList";
import Navigation from "../../components/navigation";
import HeaderTop from "../../components/headerTop";
import "./keys.scss";
import img from "../../images/GS1-ID-keys-hevtee-1.jpg"
import {useLocation} from "@reach/router";
import Layout from "../../components/layout.js";
import TableContainer from "../../components/TableContainer.js";


export default () => {

    const location = useLocation();
    return (
        <Layout aPageProp={location.pathname}>
            <div>
                <h2> GS1 Мэдээллийг Тодотгогч /Тм/</h2>

                <h3>APPLICATION IDENTIFIER - AI</h3>

                <p>GS1 системд мэдээллийг тодотгогч (МТ)-ийг GS1-128 стандартын тэмдэглэгээтэй хамт кодлогдож буй мэдээллийг илүү тодотгох зорилгоор ашигладаг. МТ-ийг 2-4 оронтой тоогоор хаалтанд тэмдэглэдэг. Эдгээр МТ-уудыг ул мөр шалган тогтоох, огноо, тоо хэмжээ, хэмжүүрүүд, байршил зэрэг маш олон мэдээллийг агуулж байдаг. Жишээ: Багц/лотын хувьд МТ дугаар нь 10 бөгөөд үүний арын мэдээллийг үсэг болон тоон мэдээллээр илэрхийлж болдгоороо онцлогтой.</p>
                <p>Дараах хүснэгтээр GS1 системд хамгийн түгээмэл хэрэглэгддэг МТ-ийг танилцуулж байна.</p>

                <TableContainer
                    titles={[
                        "МТ",
                        "Нэр",
                        "Формат",
                        "Англи нэршил"
                    ]}
                    rows={[["00", "Тээвэрлэлтийн серийн код (ТСК)", "n2+n18", "SSCC"],
                        ["01",	"Худалдааны эд зүйлсийн глобал дугаар (ХЭЗГД)",	"n2+n14", "GTIN"],
                        ["02",	"Логистикийн нэгжид агуулагдсан худалдааны эд зүйлсийн глобал дугаар",	"n2+n14",	"CONTENT"],
                        ["10",	"Багц/лотын дугаар",	"n2+an..20",	"BATCH/LOT"],
                        ["11",	"Үйлдвэрлэсэн огноо (YYMMDD)",	"n2+n6",	"PROD DATE"],
                        ["12",	"Тогтоосон хугацаа (YYMMDD)",	"n2+n6",	"DUE DATE"],
                        ["13",	"Савалсан огноо (YYMMDD)",	"n2+n6",	"PACK DATE"],
                        ["15",	"Хадгалалтын хамгийн наад хугацаа (YYMMDD)",	"n2+n6",	"BEST BEFORE or SELL BY"],
                        ["17",	"Дуусах хугацаа (YYMMDD)",	"n2+n6",	"USE BY OR EXPIRY"],
                        ["20",	"Бүтээгдэхүүний төрөл",	"n2+n2",	"VARIANT"],
                        ["21",	"Серийн дугаар",	"n2+an..20",	"SERIAL"],
                        ["22",	"Эрүүл мэндийн бүтээгдэхүүний талаарх нэмэлт мэдээлэл",	"n2+an..29",	"QTY/DATE/BATCH"],
                        ["240",	"Үйлвэрлэгчээс олгосон бүтээгдэхүүний нэмэлт мэдээлэл",	"n3+an..30",	"ADDITIONAL ID"],
                        ["241",	"Хэрэглэгчийн дугаар",	"n3+an..30",	"CUST. PART NO."],
                        ["242",	"Захиалгаар хйилгэсэн төрлийн дугаар",	"n2+n…6",	"Variation Number"],
                        ["250",	"Нэмэлт серийн дугаар",	"n3+an..30",	"SECONDARY SERIAL"],
                        ["251",	"ААН-ээс эх үүсвэртэй мэдээлэл",	"n3+an..30",	"REF. TO SOURCE"],
                        ["253",	"Баримт бичгийн глобал дугаар (ББГД)",	"n3+n13+n..17",	"DOC. ID"],
                        ["254",	"Байршлын глобал дугаар (БГД)-ын нэмэлт бүрэлдэхүүн",	"n3+an..20",	"GLN EXTENSION"],
                        ["30",	"Төрлийн тоо хэмжээ",	"n2+n..8",	"VAR. COUNT"],
                        ["310n-369n",	"Худалдааны болон логистикийн хэмжээсүүд",	"n4+n6","-"],
                        ["337n",	"Нэг кв м дэх килограмм",	"n4+n6",	"KG PER m²"],
                        ["37",	"Логистикийн нэгжид агуулагдсан худалдааны эд зүйлсийн тоо",	"n2+n..8",	"COUNT"],
                        ["390(n)",	"Төлбөр хийх мөнгөн дүн",	"n4+n..15",	"AMOUNT"],
                        ["391(n)",	"Төлбөрийн хэмжээ – ИСО мөнгөний нэгжийн код",	"n4+n3+n..15",	"AMOUNT"],
                        ["392(n)",	"Хувьсах хэмжигдэхүүнтэй худалдааны эд зүйлсийн төлбөр",	"n4+n..15",	"PRICE"],
                        ["393(n)",	"Хувьсах хэмжигдэхүүнтэй худалдааны эд зүйлсийн төлбөр -ISO мөнгөний нэгжийн код",	"n4+n3+n..15",	"PRICE"],
                        ["400",	"Хэрэглэгчийн худалдан авалтын захиалгын дугаар",	"n3+an..30",	"ORDER NUMBER"],
                        ["401",	"Ачилтын дугаар",	"n3+an..30",	"CONSIGNMENT"],
                        ["402",	"Тээвэрлэлтийг тодорхойлох дугаар",	"n3+n17",	"SHIPMENT NO."],
                        ["403",	"Замын код",	"n3+an..30",	"ROUTE"],
                        ["410",	"Ачаа хүрэх газрын БГД",	"n3+n13",	"SHIP TO LOC"],
                        ["411",	"Нэхэмжлэх илгээх БГД",	"n3+n13",	"BILL TO"],
                        ["412",	"Худалдан авсан БГД",	"n3+n13",	"PURCHASE FROM"],
                        ["413",	"Тээвэрлэх, түгээх, зуучлах БГД",	"n3+n13",	"SHIP FOR LOC"],
                        ["414",	"Бодит байршлыг тодорхойлогч БГД",	"n3+n13",	"LOC No"],
                        ["415",	"Нэхэмжлэгч талын БГД",	"n3+n13",	"PAY"],
                        ["420",	"Ачаа хүрэх газрын шуудангийн код",	"n3+an..20",	"SHIP TO POST"],
                        ["421",	"Ачаа хүрэх гакрын ИСО 3 оронтой улсын кодтой шуудангийн код",	"n3+n3+an..9",	"SHIP TO POST"],
                        ["422",	"Худалдааны эд зүйлсийн гарал үүслийн орон",	"n3+n3",	"ORIGIN"],
                        ["423",	"Анхны боловсруулалт хийсэн орон",	"n3+n3+n..12",	"COUNTRY - INITIAL PROCESS."],
                        ["424",	"Боловсруулалт хийсэн орон",	"n3+n3",	"COUNTRY - PROCESS."],
                        ["425",	"Угсралт хийсэн орон",	"n3+n3",	"COUNTRY - DISASSEMBLY"],
                        ["426",	"Боловсруулалтыг бүрэн хариуцах орон",	"n3+n3",	"COUNTRY – FULL PROCESS"],
                        ["7001",	"НАТО-ын нөөцийн дугаар",	"n4+n13",	"NSN"],
                        ["7002",	"Гулууз болон зүсэж ангилсан мах",	"n4+an..30",	"MEAT CUT"],
                        ["703(s)",	"ИСО кодтой боловсруулагчийн зөвшөөрлийн дугаар",	"n4+n3+an..27",	"PROCESSOR # s4"],
                        ["7003",	"Дуусах огноо",	"n4+n10",	"EXPIRY DATE/TIME"],
                        ["8001",	"Хуйлсан ороосон бүтээгдэхүүний өргөн урт диаметр, залгаас холбоос",	"n4+n14",	"DIMENSIONS"],
                        ["8002",	"Гар утасны электроник серийн дугаар",	"n4+an..20",	"CMT No"],
                        ["8003",	"Дахин ашиглаглах үл хөдлөх хөрөнгийн глобал дугаар (ДАҮХХГД)",	"n4+n14+an..16",	"GRAI"],
                        ["8004",	"Үндсэн үл хөдлөх хөрөнгийн глобал дугаар (ҮҮХХГД)",	"n4+an..30",	"GIAI"],
                        ["8005",	"Нэгж бүрийн үнэ",	"n4+n6",	"PRICE PER UNIT"],
                        ["8006",	"Худалдааны эд зүйлсийн багцыг тодорхойлох",	"n4+n14+n2+n2", "GCTIN"],
                        ["8007",	"Олон улсын банкны дансны дугаар",	"n4+an..30",	"IBAN"],
                        ["8008",	"Үйлдвэрлэсэн огноо",	"n4+n8+n..4",	"PROD TIME"],
                        ["8018",	"Үйлчилгээний харилцааны глобал дугаар (ҮХГД)",	"n4+n18",	"GSRN"],
                        ["8020",	"Төлбөрийн маягтын тайлбар дугаар","	n4+an..25",	"REF No"],
                        ["8100",	"GS1-128 Купоны нэмэлт код",	"n4+n1+n5",	"-"],
                        ["8101",	"GS1-128 Купоны нэмэлт код",	"n4+n1+n5+n4",	"-"],
                        ["8102",	"GS1-128 Купоны нэмэлт код",	"n4+n1+n1",	"-"],
                        ["90",	"Худалдааны түншүүдийн хооронд зөвшөөрөгдсөн харилцан тохиролцсон мэдээлэл",	"n2+an..30",	"INTERNAL"],
                        ["91-99",	"Компаний дотоод мэдээлэл",	"n2+an..30",	"INTERNAL"]]}
                    bkgdColor={"#f26334"}
                />



            </div>
        </Layout>
    )
}
